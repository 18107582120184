import React, { useState, useEffect, useMemo } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider, Drawer, Box, Typography, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import { Button } from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";
import { getUsersListsAction, deleteUsersListAction } from "../Action/action";
import Header from "../directives/header";
import { Toaster } from "react-hot-toast";
import Sidebar from "../directives/sidebar";
import CloseIcon from '@mui/icons-material/Close';

const UserLists = () => {
  const [lists, setLists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Stati per Material React Table
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  // Carica le liste al montaggio del componente
  useEffect(() => {
    fetchLists();
  }, []);

  const fetchLists = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const response = await getUsersListsAction();
      console.log(response.data.success);
      console.log(response.data.data.lists);
      if (response.data.success) {
        setLists(response.data.data.lists);
      } else {
        setIsError(true);
      }
    } catch (error) {
      console.error("Errore nel caricamento delle liste:", error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Definizione delle colonne della tabella
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID Lista",
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "name",
        header: "Nome Lista",
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "brevo_list_id",
        header: "ID Brevo",
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "created_at",
        header: "Data Creazione",
        Cell: ({ cell }) => moment(cell.getValue()).format("DD/MM/YYYY HH:mm"),
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "actions",
        header: "Azioni",
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <div style={{ display: "flex", gap: "8px" }}>
            <Button variant="contained" color="primary" size="small" onClick={() => handleExportList(row.original)}>
              Esporta
            </Button>
            <Button variant="contained" color="error" size="small" onClick={() => handleDeleteList(row.original)}>
              Elimina
            </Button>
          </div>
        ),
      },
    ],
    []
  );

  // Tema per Material React Table
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  // Configurazione della tabella
  const table = useMaterialReactTable({
    columns,
    data: lists,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    enableGlobalFilter: false,
    muiTableBodyRowProps: ({ row }) => ({
      onDoubleClick: () => {
        setSelectedList(row.original);
        setDrawerOpen(true);
      },
      sx: {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,0.05)',
        },
      },
    }),
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Errore durante il caricamento dei dati",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      sorting,
    },
  });

  // Funzione per esportare una lista in CSV
  const handleExportList = (list) => {
    setCsvLoading(true);
    try {
      const csvData = [
        ["ID Utente", "Email", "Telefono"],
        ...list.ListUsers.map((user) => [
          user.user_id,
          user.user.email,
          user.user.country_code + user.user.mobile_no,
        ]),
      ];

      setCsvData(csvData);
      // Trigger del download
      const link = document.createElement('a');
      const csvContent = csvData.map(row => row.join(';')).join('\n');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `lista_${list.name}_${new Date().toISOString().slice(0, 10)}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Errore nell'esportazione CSV:", error);
      Swal.fire({
        title: "Errore!",
        text: "Si è verificato un errore durante l'esportazione del CSV.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    } finally {
      setCsvLoading(false);
    }
  };

  // Funzione per eliminare una lista
  const handleDeleteList = async (list) => {
    const result = await Swal.fire({
      title: "Sei sicuro?",
      text: "Questa azione non può essere annullata!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sì, elimina!",
      cancelButtonText: "Annulla",
    });

    if (result.isConfirmed) {
      try {
        const response = await deleteUsersListAction(list.id);
        if (response.data.success) {
          Swal.fire({
            title: "Eliminata!",
            text: "La lista è stata eliminata con successo.",
            icon: "success",
            confirmButtonColor: "#3085d6"
          });
          fetchLists(); // Ricarica le liste
        } else {
          Swal.fire({
            title: "Errore!",
            text: response.msg || "Si è verificato un errore durante l'eliminazione della lista.",
            icon: "error",
            confirmButtonColor: "#3085d6"
          });
        }
      } catch (error) {
        console.error("Errore nell'eliminazione della lista:", error);
        Swal.fire({
          title: "Errore!",
          text: "Si è verificato un errore durante l'eliminazione della lista.",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
      }
    }
  };

  // Componente per il drawer degli utenti
  const UsersDrawer = () => (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
      PaperProps={{
        sx: {
          width: '80%',
          maxWidth: '1200px',
          backgroundColor: '#1d2330',
        }
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Typography variant="h5" sx={{ color: '#fff' }}>
            Utenti nella lista: {selectedList?.name}
          </Typography>
          <IconButton onClick={() => setDrawerOpen(false)} sx={{ color: '#fff' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <TableContainer component={Paper} sx={{ backgroundColor: '#141820', borderRadius: '8px' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#fff', borderColor: 'rgba(255,255,255,0.1)' }}>ID Utente</TableCell>
                <TableCell sx={{ color: '#fff', borderColor: 'rgba(255,255,255,0.1)' }}>Email</TableCell>
                <TableCell sx={{ color: '#fff', borderColor: 'rgba(255,255,255,0.1)' }}>Telefono</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedList?.ListUsers?.map((user) => (
                <TableRow key={user.user_id} sx={{ '&:hover': { backgroundColor: 'rgba(255,255,255,0.05)' } }}>
                  <TableCell sx={{ color: '#a2a4a9', borderColor: 'rgba(255,255,255,0.1)' }}>{user.user_id}</TableCell>
                  <TableCell sx={{ color: '#a2a4a9', borderColor: 'rgba(255,255,255,0.1)' }}>{user.user.email}</TableCell>
                  <TableCell sx={{ color: '#a2a4a9', borderColor: 'rgba(255,255,255,0.1)' }}>{user.user.country_code + user.user.mobile_no}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Drawer>
  );

  return (
    <div className="wrapper">
      <Header />
      <Toaster />
      <Sidebar />

      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Liste Utenti</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Liste create</h3>
                  </div>
                  <div className="card-body">
                    <ThemeProvider theme={tableTheme}>
                      <MaterialReactTable table={table} />
                    </ThemeProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <UsersDrawer />
    </div>
  );
};
export default UserLists;

