import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { getWithdrawlistAction, updatewithdrawAction, rejectwithdrawAction, updateWithdrawal_requestAction, sendRevolutTransfer } from "../Action/action";
import Swal from "sweetalert2";
import { Toaster } from "react-hot-toast";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Withdraw = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getpartner();
    updateWithdrawal_request();
  }, []);

  const updateWithdrawal_request = async () => {
    await updateWithdrawal_requestAction();
  };

  const getpartner = async () => {
    setIsLoading(true);
    let res = await getWithdrawlistAction();
    if (res) {
      setData(res.data);
      console.log("123", res.data);
    }
    setIsLoading(false);
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const updatebuyRequest = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Make sure withdrawal amount has been transferred to provided details by user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await updatewithdrawAction(item);
        if (res.success) {
          await Swal.fire("Approved!", res.msg, "success");
          window.location.href = `${config.baseUrl}withdraw`;
          getpartner();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const sendRevolutBankTransfer = async (id) => {
    Swal.fire({
      title: "Are you sure ?",
      text: "Revolut bank transfer",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await sendRevolutTransfer({ id });
          if (res.success) {
            await Swal.fire("Approved!", res.msg, "success");
            let list = data;
            list = list.map((item) => {
              if (item.id === id) {
                item.revolut_status = "SUCCESS";
                item.status = 1;
              }
              return item;
            });
            setData([...list]);
          } else {
            Swal.fire("Failed!", res.msg, "error");
          }
        } catch (error) {
          console.log(error.response.data.error);
          Swal.fire("Failed!", error.response.data.error, "error");
        }
      }
    });
  };

  const rejectbuyRequest = async (item) => {
    Swal.fire({
      title: "Are you sure you want to reject?",
      html: `<textarea id="txtid" placeHolder="Please provide reject reasone" className="form-control rejectbuyform" name= "reject_reason" rows="4" cols="50" maxlength="200"></textarea>`,
      text: "You want to reject this withdraw!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
      preConfirm: () => {
        const login = Swal.getPopup().querySelector("#txtid").value.trimStart();
        if (!login || login == "" || login == undefined || login == null || login == " ") {
          Swal.showValidationMessage(`Please provide reject reasone`);
        }
        return { reject_reason: login };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await rejectwithdrawAction({
          id: item,
          reject_reason: result.value.reject_reason,
        });
        if (res.success) {
          Swal.fire("Withdraw request rejected!", res.msg, "success");
          getpartner();
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  const handleCsvDownload = () => {
    const headers = ["id.", "Name", "Email", "Payment Method Details", "IBAN", "Gross Amount", "Net Amount", "Fees", "Date", "Status"];
    const csvData = [
      headers,
      ...data.map((item) => {
        const grossAmount = parseFloat(item.amount);
        const fees = grossAmount * 0.02;
        const netAmount = grossAmount - fees;
        return [
          item.id,
          item.name,
          item.email,
          item.type,
          item.iban_paypal_upi_id,
          `"${grossAmount.toFixed(2).replace('.', ',')}"`,
          `"${netAmount.toFixed(2).replace('.', ',')}"`,
          `"${fees.toFixed(2).replace('.', ',')}"`,
          item.current_datetime,
          item.status === 0 ? "Pending" : item.status === 1 ? "Approved" : item.status === 2 ? "Rejected" : "Unknown",
        ];
      })
    ];
    const fileName = "withdraw_data.csv";
    const csvString = "data:text/csv;charset=utf-8," + csvData.map((row) => row.join(",")).join("\n");
    const encodedUri = encodeURI(csvString);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.email == null ? row.original.mobile_no : row.original.email;
        },
      },
      {
        accessorKey: "payment_details",
        header: "Payment Method Details",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return (
            <>
              <b>Type : </b> {row.original.type}
              <br />
              {row.original.type === "BANK" ? (
                <>
                  <b>IBAN no. : </b> {row.original.iban_paypal_upi_id}
                  <br />
                </>
              ) : (
                ""
              )}
              {row.original.type === "PAYPAL" ? (
                <>
                  <b>Paypal Id : </b> {row.original.iban_paypal_upi_id}
                  <br />
                </>
              ) : (
                ""
              )}
              {row.original.type === "UPI" ? (
                <>
                  <b>UPI Id : </b> {row.original.iban_paypal_upi_id}
                  <br />
                </>
              ) : (
                ""
              )}
              <b>Account Holder : </b> {row.original.account_holder}
            </>
          );
        },
      },
      {
        accessorKey: "amount",
        header: "Amount",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return `€ ${row.original.amount}`;
        },
      },
      {
        accessorKey: "current_datetime",
        header: "Date & time",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "date",
        Cell: ({ row }) => {
          return row.original.current_datetime.substring(0, 10);
        },
      },
      {
        accessorKey: "status",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Pending", value: 0 },
          { text: "Approved", value: 1 },
          { text: "Rejected", value: 2 },
        ],
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status === 0 ? (
                <>
                  <button onClick={() => updatebuyRequest(row.original)} type="button" className="btn-sm btn-primary m-2">
                    Approve
                  </button>
                  &emsp;
                  <button onClick={() => rejectbuyRequest(row.original)} type="button" className="btn-sm btn-primary m-2">
                    Reject
                  </button>
                  &emsp;
                </>
              ) : row.original.status === 1 ? (
                <>
                  <span style={{ color: "green" }}>Approved</span>
                </>
              ) : row.original.status === 2 ? (
                <>
                  <span style={{ color: "red" }}>Reject</span>
                </>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        accessorKey: "revolut_action",
        header: "Revolut Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.revolut_status === "PENDING" && row.original.type === "BANK" && row.original.status == 0 && (
                <>
                  <button onClick={() => sendRevolutBankTransfer(row.original.id)} type="button" className="btn-sm btn-primary m-2">
                    Send bank transfer
                  </button>
                </>
              )}
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: false,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Withdraw Request</h4>
                          <button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                            Export CSV
                          </button>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default Withdraw;
