import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import config from "./coreFIles/config";

import Login from "./component/login";
import Dashboard from "./component/dashboard";
import Changepassword from "./component/changepassword";
import Category from "./component/category";
import Addcategory from "./component/addcategory";
import Categoryupdate from "./component/updatecategory";
import ItemList from "./component/itemList";
import ItemAdd from "./component/itemAdd";
import AlertRequest from "./component/alert_request";
import Notification from "./component/Notification";
import UserGuide from "./component/UserGuide";
import ExpertDetails from "./component/ExpertDetails";
import Withdraw from "./component/Withdraw";
import Referrals from "./component/referrals";
import PromoModal from "./component/promoModal";
import UserActions from "./component/userActions";
import GiftCards from "./component/giftCards";
import TransactionsV2 from "./component/transactionsV2";
import UsersV2 from "./component/usersV2";
import UserDetail from "./component/userDetail";
import Magazzini from "./component/Magazzini";
import Fornitori from "./component/Fornitori";
import FornitoreDettaglio from "./component/FornitoreDettaglio";
import MagazzinoDettaglio from "./component/MagazzinoDettaglio";
import GiftItems from "./component/giftItems";
import Cashback from "./component/Cashback";
import Pagamenti from "./component/Pagamenti";
import BankTransfersV2 from "./component/BankTransfersV2";
import { QueryBuilderPage } from "./component/QueryBuilder";
import UserLists from "./component/UserLists";

function App() {
  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path={`${config.baseUrl}`} element={<Login />} />
          <Route path={`${config.baseUrl}dashboard`} element={<Dashboard />} />
          <Route path={`${config.baseUrl}V2users`} element={<UsersV2 />} />
          <Route path={`${config.baseUrl}userDetails`} element={<UserDetail />} />
          <Route path={`${config.baseUrl}changepassword`} element={<Changepassword />} />
          <Route path={`${config.baseUrl}Category`} element={<Category />} />
          <Route path={`${config.baseUrl}Addcategory`} element={<Addcategory />} />
          <Route path={`${config.baseUrl}Categoryupdate/:id`} element={<Categoryupdate />} />
          <Route path={`${config.baseUrl}itemList`} element={<ItemList />} />
          <Route path={`${config.baseUrl}itemAdd`} element={<ItemAdd />} />
          <Route path={`${config.baseUrl}itemAdd/:id`} element={<ItemAdd />} />
          <Route path={`${config.baseUrl}alert_request`} element={<AlertRequest />} />
          <Route path={`${config.baseUrl}V2transactions`} element={<TransactionsV2 />} />
          <Route path={`${config.baseUrl}giftCards`} element={<GiftCards />} />
          <Route path={`${config.baseUrl}notification`} element={<Notification />} />
          <Route path={`${config.baseUrl}userguide`} element={<UserGuide />} />
          <Route path={`${config.baseUrl}expertdetails`} element={<ExpertDetails />} />
          <Route path={`${config.baseUrl}withdraw`} element={<Withdraw />} />
          <Route path={`${config.baseUrl}referrals`} element={<Referrals />} />
          <Route path={`${config.baseUrl}promo`} element={<PromoModal />} />
          <Route path={`${config.baseUrl}userActions`} element={<UserActions />} />
          <Route path={`${config.baseUrl}magazzini`} element={<Magazzini />} />
          <Route path={`${config.baseUrl}fornitori`} element={<Fornitori />} />
          <Route path={`${config.baseUrl}fornitori/:id`} element={<FornitoreDettaglio />} />
          <Route path={`${config.baseUrl}magazzini/:id`} element={<MagazzinoDettaglio />} />
          <Route path={`${config.baseUrl}giftItems`} element={<GiftItems />} />
          <Route path={`${config.baseUrl}cashback`} element={<Cashback />} />
          <Route path={`${config.baseUrl}pagamenti`} element={<Pagamenti />} />
          <Route path={`${config.baseUrl}bank-transfers`} element={<BankTransfersV2 />} />
          <Route path={`${config.baseUrl}query-builder`} element={<QueryBuilderPage />} />
          <Route path={`${config.baseUrl}user-lists`} element={<UserLists />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
