import React, { useCallback, useEffect, useMemo, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getAllTransactions, getDailyTransactionsByItem, getTransactionsV2Action, getUsersLiquidity, setUsersLiquidity, setReferralBonus, } from "../Action/action";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Button, createTheme, ThemeProvider, useTheme } from "@mui/material";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";

const TransactionsV2 = () => {
  //data and fetching state
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  //Liquidity Modal
  const [item, setItem] = useState({});
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [saving, setsaving] = useState(false);
  const [lqiAmt, setLqiAmt] = useState(0);
  const [isTransfer, setIsTransfer] = useState(false);
  const [validatioError, setvalidatioError] = useState({});



  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  //if you want to avoid useEffect, look at the React Query example instead
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark", //let's use the same dark/light mode as the global theme
        },
      }),
    []
  );
  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        // Use the modified filters for the API call
        let filtersToUse = JSON.stringify(columnFilters ?? []);
        /* const urlParams = new URLSearchParams(window.location.search);
        const urlPage = urlParams.get("page");
        const urlLimit = urlParams.get("limit");
        const urlFilters = urlParams.get("filters");

        // Check if it's the first page load and there are filters in the URL
        if (data.length === 0 && (urlPage !== null || urlLimit !== null || urlFilters !== null) && pageIndex === 0 && pageSize === 10) {
          if (urlFilters) {
            filtersToUse = urlFilters;
            setColumnFilters(JSON.parse(urlFilters));
          }
          if (urlPage) {
            pageIndex = parseInt(urlPage);
            setPagination((prev) => ({ ...prev, pageIndex, pageSize }));
          }
        } */

        // Construct the URL with query parameters
        /* const url = `${config.baseUrl}V2transactions?page=${pageIndex}&limit=${pageSize}${filtersToUse !== "[]" ? `&filters=${encodeURIComponent(filtersToUse)}` : ""}`;
        // Update URL without refreshing the page
        window.history.pushState(null, "", url); */

        const response = await getTransactionsV2Action(pagination.pageIndex, pagination.pageSize, filtersToUse);
        setData(response.data.transactions);
        setRowCount(response.data.totalItems);
      } catch (error) {
        setIsError(true);
        console.error(error);
        return;
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters, //re-fetch when column filters change
    globalFilter, //re-fetch when global filter changes
    pagination.pageIndex, //re-fetch when page index changes
    pagination.pageSize, //re-fetch when page size changes
    sorting, //re-fetch when sorting changes
  ]);

  useEffect(() => {
    setCsvData([]);
  }, [columnFilters]);

  const getDailyTransactions = async () => {
    const response = await getDailyTransactionsByItem();
    console.log(response);
    if (response.success) {
      const csvData = [
        ["Date", "Item ID", "Item Name", "Total Amount", "Total Fees", "Total Amount with Fees", "Total Bonus Liquidity Used"],
        ...response.data.map((item) => [
          item.date,
          item.item_id,
          `"${item["item.name"]}"`,
          `"${item.total_amount.toString().replace(".", ",")}"`,
          `"${item.total_fees.toString().replace(".", ",")}"`,
          `"${item.total_amount_with_fees.toString().replace(".", ",")}"`,
          `"${item.total_bonus_liquidity.toString().replace(".", ",")}"`,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "daily_transactions_by_item.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error("Failed to retrieve daily transactions");
    }
  };

  const handleCsvDownload = async () => {
    try {
      setCsvLoading(true);
      const response = await getAllTransactions(JSON.stringify(columnFilters ?? []));
      if (response.data.transactions.length > 0) {
        console.log(response.data.transactions);
        console.log(
          (response.data.transactions[0].amount * 1.025 - response.data.transactions[0].bonus_liquidity_used - response.data.transactions[0].user_liquidity_used)
            .toFixed(2)
            .toLocaleString()
        );
        const csvData = [
          [
            "ID",
            "Email",
            "Sign Up Date",
            "Mobile No",
            "Item Name",
            "Category",
            "Market Type",
            "Price",
            "Amount",
            "Stripe Amount",
            "Share Quantity",
            "User Liquidity Used",
            "Bonus Liquidity Used",
            "Bank Liquidity Used",
            "Fees",
            "Type",
            "Payment Method",
            "Id Transaction",
            "Date",
            "User Transaction Index",
            "Item ID"
          ],
          ...response.data.transactions.map((item) => [
            item.id,
            item.user.email,
            moment(item.user.created_at).format("DD-MM-YYYY HH:mm:ss"),
            item.user.country_code + item.user.mobile_no,
            item.item.name,
            item.item.category.category_name,
            item.market_type,
            item.price.toLocaleString(),
            parseInt(item.amount).toLocaleString(),
            item.amount * 1.025 - item.bonus_liquidity_used - item.user_liquidity_used - item.bank_liquidity_used < 0.1
              ? "0"
              : (item.amount * 1.025 - item.bonus_liquidity_used - item.user_liquidity_used - item.bank_liquidity_used).toLocaleString(),
            item.share_qty.toLocaleString(),
            item.user_liquidity_used.toLocaleString(),
            item.bonus_liquidity_used.toLocaleString(),
            item.bank_liquidity_used.toLocaleString(),
            (item.amount * 0.025).toLocaleString(),
            item.trx_type_id === 1 ? "Buy" : "Sell",
            item.payment_method,
            item.stripe_trx_id,
            moment(item.datetime).format("DD-MM-YYYY HH:mm:ss"),
            item.userTransactionIndex,
            item.item.id,

          ]),
        ];
        setCsvData(csvData);
        setCsvLoading(false);
      }
    } catch (error) {
      setCsvLoading(false);
      setCsvData([]);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Transaction ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "user.email",
        header: "Email",
        enableSorting: false,
      },
      {
        accessorKey: "user.mobile_no",
        header: "Phone Number",
        enableSorting: false,
        accessorFn: (row) => row.user.country_code + row.user.mobile_no,
      },
      {
        accessorKey: "item.name",
        header: "Item Name",
        enableSorting: false,
      },
      {
        accessorKey: "item.category.category_name",
        header: "Category",
        enableSorting: false,
      },
      {
        accessorKey: "market_type",
        header: "Market Type",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "PRIMARY", value: "PRIMARY" },
          { text: "SECONDARY", value: "SECONDARY" },
        ],
      },
      {
        accessorKey: "liquidity",
        header: "Liquidity",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (
          <>
            {!row.is_deleted && row.trx_type_id === 1 && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleLiquidityModal(row);
                }}
                type="button"
                className="btn btn-sm btn-primary"
              >
                Liquidity
              </button>
            )}
          </>
        ),
      },
      {
        accessorKey: "price",
        header: "Price",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "share_qty",
        header: "Quantity",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => Number(row.amount).toFixed(2),
      },
      {
        accessorKey: "stripe_amount",
        header: "Stripe Amount",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) =>
          row.amount * 1.025 - row.bonus_liquidity_used - row.bank_liquidity_used - row.user_liquidity_used < 0.1
            ? 0
            : (row.amount * 1.025 - row.bonus_liquidity_used - row.bank_liquidity_used - row.user_liquidity_used).toFixed(2),
      },
      {
        accessorKey: "bonus_liquidity_used",
        header: "Bonus Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.bonus_liquidity_used.toFixed(2),
      },
      {
        accessorKey: "user_liquidity_used",
        header: "User Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.user_liquidity_used.toFixed(2),
      },
      {
        accessorKey: "bank_liquidity_used",
        header: "Bank Liquidity Used",
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => row.bank_liquidity_used,
      },
      {
        accessorKey: "trx_type_id",
        header: "Transaction Type",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Buy", value: 1 },
          { text: "Sell", value: 2 },
        ],
        accessorFn: (row) => (row.trx_type_id === 1 ? "Buy" : "Sell"),
      },
      {
        accessorKey: "payment_method",
        header: "Payment Method",
        enableSorting: false,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "CARD", value: "CARD" },
          { text: "ALL_LIQUIDITY", value: "ALL_LIQUIDITY" },
          { text: "ADMIN_BUYBACK", value: "ADMIN_BUYBACK" },
        ],
      },
      {
        accessorKey: "stripe_trx_id",
        header: "Stripe Transaction ID",
        enableSorting: false,
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableSorting: false,
        filterVariant: "date",
        accessorFn: (row) => moment(row.datetime).format("DD-MM-YYYY HH:mm:ss"),
      },
      
    ],
    []
  );

  async function handleLiquidityModal(item) {
    getUsersLiquidity({ user_id: item.user_id })
      .then((res) => {
        if (res.success) {
          setItem({
            ...item,
            liquidity_amount: res.liquidity_amount,
            withdrawable_liquidity_amount: res.withdrawable_liquidity_amount,
            primary_bonus_liquidity_amount: res.primary_bonus_liquidity_amount,
            secondary_bonus_liquidity_amount: res.secondary_bonus_liquidity_amount,
          });
          setShowModal(true);
        } else {
          setItem(item);
          throw new Error("Something went while fetching liquidity");
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setShowModal(true);
      });
  }

  async function handleLiquiditySubmit(e) {
    e.preventDefault();

    if (lqiAmt === 0 || lqiAmt === null || lqiAmt === undefined || lqiAmt === "") {
      setvalidatioError({ ...validatioError, lqiAmt: true });
      return;
    }

    setvalidatioError({});
    setsaving(true);
    setUsersLiquidity({ user_id: item.user_id, bonus_liquidity: lqiAmt, is_bank_transfer: isTransfer })
      .then((res) => {
        if (res.success) {
          Swal.fire("Success", res.msg, "success");
        } else {
          Swal.fire("Failed", res.msg, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire("Failed", "Something went wrong", "error");
      })
      .finally(() => {
        setShowModal(false);
        setsaving(false);
        setIsTransfer(false);
      });
  }
  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,

    getRowId: (row) => row.phoneNumber,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    //enableStickyHeader: true,

    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f", //add a border between columns
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "0.1px solid #ffffff1f", //add a border between columns
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={tableTheme}>
        <div className="wrapper">
          {/* <div id="loader"></div> */}
          <Header />
          <Sidebar />
          <div className="content-wrapper">
            <div className="container-full">
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">Transactions List</h4>
                        <Button className="btn btn-sm btn-primary" onClick={getDailyTransactions}>
                          Export totals by day
                        </Button>
                        {csvLoading ? (
                          <div className="text-center">
                            <div className="spinner-border" role="status" aria-hidden="true"></div>
                          </div>
                        ) : csvData.length > 0 ? (
                          <CSVLink data={csvData} filename="Transactions.csv" className="btn btn-sm btn-primary">
                            Export CSV
                          </CSVLink>
                        ) : (
                          <>
                            <Button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                              Load CSV
                            </Button>
                          </>
                        )}
                      </div>
                      <div className="box-body">
                        <MaterialReactTable table={table} />
                      </div>
                    </div>
                  </div>
                </div>
                {/*Add Liquidity Modal */}
                {showModal && (
                  <div className="modal fade show" id="liquidityModal" tabIndex={-1} aria-labelledby="liquidityModalLabel" aria-hidden="true" style={{ display: "block" }}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        {/* await items is not empty */}
                        {Object.keys(item).length > 0 ? (
                          <>
                            <div className="modal-header" data-bs-theme="dark">
                              <h5 className="modal-title" id="liquidityModalLabel">
                                Liquidity for {item.user.name} {item.user.cognome}
                              </h5>
                              <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
                            </div>

                            {error ? (
                              <div className="modal-body">
                                <div className="container">
                                  <div className="mb-3">
                                    <p className="text-center">Something went wrong. Please try again later</p>
                                  </div>
                                  <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                    Close
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <form onSubmit={(e) => handleLiquiditySubmit(e)}>
                                <div className="modal-body">
                                  <div className="container">
                                    <div className="mb-4">
                                      {/** Liquidity Details some p whit some data */}
                                      <p>
                                        <strong>Available Liquidity:</strong> {item.liquidity_amount}
                                      </p>
                                      <p>
                                        <strong>Withdrawable Liquidity:</strong> {item.withdrawable_liquidity_amount}
                                      </p>
                                      <p>
                                        <strong>Primary Bonus Liquidity:</strong> {item.primary_bonus_liquidity_amount}
                                      </p>
                                      <p>
                                        <strong>Secondary Bonus Liquidity:</strong> {item.secondary_bonus_liquidity_amount}
                                      </p>
                                    </div>

                                    <div className="mt-4 mb-3">
                                      <h5 className="text-center mt-5">Add Liquidity</h5>
                                      <label htmlFor="exampleFormControlInput1" className="form-label">
                                        Liquidity Amount
                                      </label>
                                      <input
                                        type="number"
                                        step=".01"
                                        className="form-control"
                                        id="exampleFormControlInput1"
                                        placeholder="Enter Liquidity Amount"
                                        onChange={(e) => setLqiAmt(e.target.value)}
                                        name="liquidity_amount"
                                      />
                                      {validatioError.lqiAmt && <span className="validationErr mt-1">Liquidity amount error</span>}
                                      <div className="form-check mt-3 p-0">
                                        <input
                                          className="form-check-input d-none"
                                          type="checkbox"
                                          id="liquidityTransferCheckbox"
                                          onChange={(e) => setIsTransfer(e.target.checked)}
                                        />
                                        <label className="form-check-label" htmlFor="liquidityTransferCheckbox">
                                          Is bank transfer?
                                        </label>
                                      </div>
                                    </div>

                                    <div className="modal-footer mt-20">
                                      {!saving ? (
                                        <button type="submit" className="btn btn-primary">
                                          Add Liquidity
                                        </button>
                                      ) : (
                                        <button type="submit" className="btn btn-primary" disabled>
                                          Please wait <i className="fa fa-spinner fa-spin"></i>
                                        </button>
                                      )}

                                      <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                                        Close
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            )}

                          </>
                        ) : (
                          <>
                            <div className="modal-header" data-bs-theme="dark">
                              <h5 className="modal-title" id="liquidityModalLabel">
                                Add Liquidity
                              </h5>
                              <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss="modal" onClick={() => setShowModal(false)} />
                            </div>
                            {/* on body fa-spin and close button */}
                            <div className="modal-body">
                              <div className="container">
                                <div className="mb-3">
                                  <p className="text-center">Please select a user to add liquidity</p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </section>
            </div>
          </div>

          <Footer />
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  );
};
export default TransactionsV2;
