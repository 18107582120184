import React, { useEffect, useState, useMemo } from "react";
import config from "../coreFIles/config";
import { Toaster } from "react-hot-toast";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getUserActions } from "../Action/action";
import moment from "moment";
import { createTheme, ThemeProvider, Button } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";

const UserActions = () => {
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        const response = await getUserActions(
          pagination.pageIndex,
          pagination.pageSize,
          JSON.stringify(columnFilters ?? [])
        );
        
        if (response.success) {
          const formattedData = response.data.userActions.map((item, index) => ({
            id: item.id,
            email: item.user.email,
            username: item.user.name,
            phone: item.user.country_code + " " + item.user.mobile_no,
            action_type: item.action_type,
            item: item.item.name,
            category: item.item.category.category_name,
            date: item.createdAt,
          }));
          setData(formattedData);
          setRowCount(response.data.totalItems);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };
    fetchData();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "username",
        header: "Username",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "phone",
        header: "Phone",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "action_type",
        header: "Type",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "category",
        header: "Category",
        enableSorting: false,
      },
      {
        accessorKey: "item",
        header: "Item",
        enableSorting: false,
      },
      {
        accessorKey: "date",
        header: "Date",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => moment(row.original.date).format("DD-MM-YYYY HH:mm:ss"),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330", 
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  const handleCsvDownload = async () => {
    try {
      setCsvLoading(true);
      const response = await getUserActions(0, 2999999, JSON.stringify(columnFilters ?? []));
      if (response.success && response.data.userActions.length > 0) {
        console.log(response.data.userActions[0]);
        const csvData = [
          [
            "ID",
            "Email",
            "Username",
            "Phone",
            "Action Type",
            "Category",
            "Item",
            "Date"
          ],
          ...response.data.userActions.map((item) => [
            item.id,
            item.user?.email,
            item.user?.name,
            item.user?.country_code + item.user?.mobile_no,
            item.action_type,
            item.item?.category?.category_name,
            item.item?.name,
            moment(item.createdAt).format("DD-MM-YYYY HH:mm:ss")
          ]),
        ];
        setCsvData(csvData);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setCsvLoading(false);
    }
  };

  useEffect(() => {
    setCsvData([]);
  }, [columnFilters]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Toaster />
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">User actions</h4>
                          {csvLoading ? (
                            <div className="text-center">
                              <div className="spinner-border" role="status" aria-hidden="true"></div>
                            </div>
                          ) : csvData.length > 0 ? (
                            <CSVLink data={csvData} filename="UserActions.csv" className="btn btn-sm btn-primary">
                              Export CSV
                            </CSVLink>
                          ) : (
                            <Button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                              Load CSV
                            </Button>
                          )}
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default UserActions;
