import React, { useState, useEffect, useCallback, useMemo } from "react";
import "./QueryBuilderV2.css";
import Select from "react-select";
import debounce from "lodash/debounce";
import { getCategoryListAction, getItemListAction, filterUsersAction, createUsersListAction } from "../Action/action";
import config from "../coreFIles/config";
import moment from "moment";
import { Button, createTheme, ThemeProvider, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import Swal from "sweetalert2";

const FILTER_TYPES = {
  COLLECTION_AMOUNT: "Importo collezione",
  CATEGORY_SPEND: "Importo speso per categoria",
  FIRST_PURCHASE_DATE: "Data primo acquisto",
  FAVORITE_ITEMS: "Beni nei Preferiti",
  OWNED_ITEMS: "Beni posseduti",
  CASHBACK_USAGE: "Cashback utilizzato da",
};

const SORT_FIELDS = {
  USER_WALLET: "Portafoglio utente",
  CATEGORY_SPEND: "Spesa per categoria",
  FIRST_PURCHASE_DATE: "Data primo acquisto",
};

const SORT_DIRECTIONS = {
  ASC: "Crescente",
  DESC: "Decrescente",
};

const QueryBuilderV2 = () => {
  // Stato per le categorie caricate dall'API
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [error, setError] = useState(null);

  // Inizializziamo con un gruppo e un filtro precompilato
  const [groups, setGroups] = useState([
    {
      id: 1,
      filters: [
        {
          id: 1,
          type: "COLLECTION_AMOUNT",
          operator: "GREATER_THAN",
          value: "",
          category: "",
          startDate: "",
          endDate: "",
          selectedItems: [],
          searchTerm: "",
        },
      ],
    },
  ]);
  // Stato per beni
  const [items, setItems] = useState([]);
  const [loadingItems, setLoadingItems] = useState(false);

  // Stato per tenere traccia dei gruppi compressi
  const [collapsedGroups, setCollapsedGroups] = useState(new Set());

  // Rileva la modalità dark/light
  const [isDarkMode, setIsDarkMode] = useState(document.body.classList.contains("dark-skin"));

  // Stato per memorizzare gli elementi selezionati per ogni filtro
  const [selectedItemsMap, setSelectedItemsMap] = useState({});

  // Aggiungo stati per limite e skip
  const [userLimit, setUserLimit] = useState(10000);
  const [userSkip, setUserSkip] = useState(0);

  // Aggiungo stati per ordinamento
  const [sortField, setSortField] = useState("USER_WALLET");
  const [sortDirection, setSortDirection] = useState("DESC");
  const [sortCategory, setSortCategory] = useState("");

  // Aggiungo stati per il risultato della ricerca
  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [tableData, setTableData] = useState([]);

  const [tableRowCount, setTableRowCount] = useState(0);
  const categoriesToShow = [1, 2, 9, 15, 18, 19, 20, 21, 22, 23, 24];

  // Stati per Material React Table
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  // Stati per tabella
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [showCreateListModal, setShowCreateListModal] = useState(false);
  const [listName, setListName] = useState("");
  const [isCreatingList, setIsCreatingList] = useState(false);

  // Aggiungi questo stato per gestire gli errori del modale
  const [modalError, setModalError] = useState(null);

  // Funzioni per caricare dati al caricamento del componente
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    handleCsvDownload();
  }, [tableData]);

  const fetchCategories = async () => {
    setLoadingCategories(true);

    try {
      const response = await getCategoryListAction();
      setCategories(response.data.filter((el) => categoriesToShow.includes(el.id)) || []);
      setError(null);
    } catch (err) {
      console.error("Errore nel caricamento delle categorie:", err);
      setError("Impossibile caricare le categorie. Riprova più tardi.");
      setCategories([]);
    } finally {
      setLoadingCategories(false);
    }
  };

  // Funzione modificata per la ricerca degli elementi
  const debouncedSearch = useCallback(
    debounce((searchQuery, groupId, filterId) => {
      fetchItems(searchQuery, groupId, filterId);
    }, 500),
    []
  );

  // Funzione modificata per caricare gli elementi
  const fetchItems = async (searchQuery = "", groupId, filterId) => {
    setLoadingItems(true);
    const limit = searchQuery ? 10000 : 20;

    try {
      const filters = searchQuery ? JSON.stringify([{ id: "name", value: searchQuery }]) : "";

      let res = await getItemListAction(0, limit, filters);

      if (res.success) {
        // Creiamo una mappa delle opzioni
        let selectList = res.data.items.map((el) => ({
          value: el.id,
          label: el.name,
          image: config.imageUrl + el.image,
        }));

        // Se abbiamo un filtro attivo, aggiungiamo gli elementi selezionati
        if (groupId && filterId) {
          const mapKey = `${groupId}-${filterId}`;

          // Recuperiamo gli elementi selezionati per questo filtro
          const selectedItems = selectedItemsMap[mapKey] || [];

          // Assicuriamoci che gli elementi selezionati siano inclusi nelle opzioni
          // anche se non corrispondono alla ricerca
          if (selectedItems.length > 0) {
            // Filtra per mantenere solo gli elementi selezionati che non sono già nella lista
            const selectedItemIds = new Set(selectList.map((item) => item.value));
            const missingSelectedItems = selectedItems.filter((item) => !selectedItemIds.has(item.value));

            // Aggiungi gli elementi selezionati mancanti alla lista
            selectList = [...selectList, ...missingSelectedItems];
          }
        }

        setItems(selectList);
      } else {
        setItems([]);
      }
    } catch (err) {
      console.error("Errore nel caricamento degli item:", err);
      setItems([]);
    } finally {
      setLoadingItems(false);
    }
  };

  const addFilter = (groupId) => {
    setGroups(
      groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            filters: [
              ...group.filters,
              {
                id: Date.now(),
                type: "COLLECTION_AMOUNT",
                operator: "GREATER_THAN",
                value: "",
                valueStart: "",
                valueEnd: "",
                category: "",
                startDate: "",
                endDate: "",
                selectedItems: [],
                searchTerm: "",
              },
            ],
          };
        }
        return group;
      })
    );
  };

  const addGroup = () => {
    setGroups([
      ...groups,
      {
        id: Date.now(),
        filters: [
          {
            id: Date.now(),
            type: "COLLECTION_AMOUNT",
            operator: "GREATER_THAN",
            value: "",
            valueStart: "",
            valueEnd: "",
            category: "",
            startDate: "",
            endDate: "",
            selectedItems: [],
            searchTerm: "",
          },
        ],
      },
    ]);
  };

  const removeFilter = (groupId, filterId) => {
    setGroups(
      groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            filters: group.filters.filter((f) => f.id !== filterId),
          };
        }
        return group;
      })
    );
  };

  const removeGroup = (groupId) => {
    if (groups.length <= 1) {
      return;
    }
    setGroups(groups.filter((g) => g.id !== groupId));
  };

  const updateFilter = (groupId, filterId, field, value) => {
    setGroups(
      groups.map((group) => {
        if (group.id === groupId) {
          return {
            ...group,
            filters: group.filters.map((filter) => {
              if (filter.id === filterId) {
                // Se stiamo cambiando il tipo, aggiorniamo anche l'operatore
                if (field === "type") {
                  let defaultOperator = "GREATER_THAN";
                  if (value === "FIRST_PURCHASE_DATE") defaultOperator = "EXACT_DATE";
                  if (value === "CASHBACK_USAGE") defaultOperator = "EXACT_DATE";
                  if (value === "FAVORITE_ITEMS" || value === "OWNED_ITEMS") {
                    defaultOperator = "HAS_ITEMS";
                    // Carichiamo gli item quando si seleziona un filtro di tipo item
                    fetchItems();
                  }
                  if (value === "CATEGORY_SPEND") {
                    filter.category = categories[0].id;
                  }

                  return {
                    ...filter,
                    type: value,
                    operator: defaultOperator,
                  };
                }
                return { ...filter, [field]: value };
              }
              return filter;
            }),
          };
        }
        return group;
      })
    );
  };

  const handleClear = () => {
    setGroups([
      {
        id: Date.now(),
        filters: [
          {
            id: Date.now(),
            type: "COLLECTION_AMOUNT",
            operator: "GREATER_THAN",
            value: "",
            valueStart: "",
            valueEnd: "",
            category: "",
            startDate: "",
            endDate: "",
            selectedItems: [],
            searchTerm: "",
          },
        ],
      },
    ]);
  };

  // Nuova funzione per eseguire la ricerca
  const handleSearch = async () => {
    // Creiamo la query da inviare
    const queryToSend = {
      limit: userLimit,
      skip: userSkip,
      sort: sortField
        ? {
            field: sortField,
            direction: sortDirection,
            ...(sortField === "CATEGORY_SPEND" && { categoryId: sortCategory }),
          }
        : null,
      groups: groups.map((group) => ({
        id: group.id,
        filters: group.filters.map((filter) => {
          const cleanFilter = {
            type: filter.type,
            operator: filter.operator,
          };

          // Aggiungiamo i campi specifici in base al tipo
          if (filter.type === "COLLECTION_AMOUNT") {
            cleanFilter.value = filter.value;
            cleanFilter.valueStart = filter.valueStart;
            cleanFilter.valueEnd = filter.valueEnd;
          } else if (filter.type === "CATEGORY_SPEND") {
            cleanFilter.category = filter.category;
            cleanFilter.value = filter.value;
            cleanFilter.valueStart = filter.valueStart;
            cleanFilter.valueEnd = filter.valueEnd;
          } else if (filter.type === "FIRST_PURCHASE_DATE") {
            if (filter.operator === "DATE_RANGE") {
              cleanFilter.startDate = filter.startDate;
              cleanFilter.endDate = filter.endDate;
            } else {
              cleanFilter.date = filter.value;
            }
          } else if (filter.type === "FAVORITE_ITEMS" || filter.type === "OWNED_ITEMS") {
            cleanFilter.items = filter.selectedItems;
          } else if (filter.type === "CASHBACK_USAGE") {
            cleanFilter.date = filter.value;
          }

          return cleanFilter;
        }),
      })),
    };

    // Eseguiamo la ricerca
    setIsLoading(true);
    setIsRefetching(true);
    setIsError(false);

    try {
      const result = await filterUsersAction(queryToSend);
      if (result.status === "success" && result.data?.users) {
        setTableData(result.data.users);
        setRowCount(result.data.users.length);
        setSearchResults(result.data);
        
      } else {
        setIsError(true);
      }
    } catch (err) {
      console.error("Errore nell'esecuzione della ricerca:", err);
      setIsError(true);
    } finally {
      setIsLoading(false);
      setIsRefetching(false);
      setIsSearching(false);
    }
  };

  // Funzione per preparare i dati per l'esportazione CSV
  const handleCsvDownload = () => {
    if (!tableData || tableData.length === 0) return;

    setCsvLoading(true);

    try {
      const csvData = [
        ["ID Utente", "Email", "Telefono", "Data Registrazione", "Valore Portafoglio", "Num. Beni", ...categories.map((cat) => cat.category_name)],
        ...tableData.map((user) => {
          const categoryValues = categories.map((cat) => {
            const catKey = `category_${cat.id}`;
            return user[catKey] ? parseFloat(user[catKey]).toLocaleString("it-IT") : "0";
          });

          return [
            user.user_id,
            user.email,
            user.country_code + user.mobile_no,
            moment(user.created_at).format("DD/MM/YYYY HH:mm:ss"),
            parseFloat(user.total_portfolio_value).toLocaleString("it-IT"),
            user.number_of_items,
            ...categoryValues,
          ];
        }),
      ];
      console.log(csvData);

      setCsvData(csvData);
    } catch (error) {
      console.error("Errore nell'esportazione CSV:", error);
    } finally {
      setCsvLoading(false);
    }
  };

  // Funzione per gestire il cambio del campo di ordinamento
  const handleSortFieldChange = (e) => {
    const value = e.target.value;
    setSortField(value);
    if (value === "CATEGORY_SPEND") {
      setSortCategory(categories[0].id);
    }

    // Reset della categoria se non è più rilevante
    if (value !== "CATEGORY_SPEND") {
      setSortCategory("");
    }
  };
  // Funzione per gestire la compressione/espansione dei gruppi
  const toggleGroupCollapse = (groupId) => {
    setCollapsedGroups((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(groupId)) {
        newState.delete(groupId);
      } else {
        newState.add(groupId);
      }
      return newState;
    });
  };

  // Funzione per aggiornare elementi selezionati nella mappa
  const updateSelectedItems = (groupId, filterId, selectedOptions) => {
    const mapKey = `${groupId}-${filterId}`;

    // Aggiorniamo la mappa degli elementi selezionati
    setSelectedItemsMap((prev) => ({
      ...prev,
      [mapKey]: selectedOptions || [],
    }));

    // Aggiorniamo anche il filtro
    updateFilter(groupId, filterId, "selectedItems", selectedOptions ? selectedOptions.map((option) => option.value) : []);
  };

  // Definizione delle colonne della tabella
  const tableColumns = useMemo(() => {
    if (!tableData || tableData.length === 0) return [];

    const firstItem = tableData[0];
    const baseColumns = [
      {
        accessorKey: "user_id",
        header: "ID Utente",
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "email",
        header: "Email",
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "phone",
        header: "Telefono",
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => (row.mobile_no ? row.country_code + row.mobile_no : "-"),
      },
      {
        accessorKey: "created_at",
        header: "Data Registrazione",
        Cell: ({ cell }) => moment(cell.getValue()).format("DD/MM/YYYY HH:mm"),
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "total_portfolio_value",
        header: "Valore Portafoglio",
        Cell: ({ cell }) => `${parseFloat(cell.getValue()).toLocaleString("it-IT")} €`,
        size: 150,
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "number_of_items",
        header: "Num. Beni",
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
      },
    ];

    const categoryColumns = Object.keys(firstItem)
      .filter((key) => key.startsWith("category_"))
      .sort((a, b) => {
        const numA = parseInt(a.split("_")[1]);
        const numB = parseInt(b.split("_")[1]);
        return numA - numB;
      })
      .map((catKey) => {
        const catId = catKey.split("_")[1];
        const category = categories.find((c) => c.id === parseInt(catId));

        return {
          accessorKey: catKey,
          header: category ? category.category_name : `Cat. ${catId}`,
          Cell: ({ cell }) => {
            const value = parseFloat(cell.getValue());
            return value > 0 ? `${value.toLocaleString("it-IT")} €` : "-";
          },
          size: 130,
          enableSorting: false,
          enableColumnFilter: false,
        };
      });

    return [...baseColumns, ...categoryColumns];
  }, [tableData, categories]);

  // Tema per Material React Table - identico a usersV2.js
  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  // Configurazione della tabella usando useMaterialReactTable
  const table = useMaterialReactTable({
    columns: tableColumns,
    data: tableData,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: false },
    manualFiltering: true,
    manualPagination: false,
    manualSorting: true,
    enableGlobalFilter: false,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Errore durante il caricamento dei dati",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  // Modifica la funzione handleCreateList
  const handleCreateList = async () => {
    if (!listName.trim()) {
      setModalError("Inserisci un nome per la lista");
      return;
    }

    setIsCreatingList(true);
    setModalError(null);

    try {
      const listData = {
        name: listName.trim(),
        users: tableData.map((user) => user.user_id),
      };

      const res = await createUsersListAction(listData);
      console.log(res);
      if (res.data.success) {
        Swal.fire({
          title: "Successo!",
          text: "Lista creata con successo",
          icon: "success",
          confirmButtonColor: "#3085d6",
        });
        setShowCreateListModal(false);
        setListName("");
        setModalError(null);
      } else {
        setModalError(res.msg || "Errore durante la creazione della lista");
      }
    } catch (error) {
      console.error("Errore nella creazione della lista:", error);
      setModalError("Si è verificato un errore durante la creazione della lista");
    } finally {
      setIsCreatingList(false);
    }
  };

  return (
    <div className={`query-builder ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      {/* Aggiungo il componente per limite e skip all'inizio */}

      {groups.map((group, groupIndex) => (
        <React.Fragment key={group.id}>
          <div className={`group-section ${collapsedGroups.has(group.id) ? "collapsed" : ""}`}>
            <div className="group-header">
              <div className="group-header-left">
                <button className="collapse-button" onClick={() => toggleGroupCollapse(group.id)}>
                  {collapsedGroups.has(group.id) ? <i className="fa fa-chevron-right"></i> : <i className="fa fa-chevron-down"></i>}
                </button>
                <div className="group-title">
                  Gruppo {groupIndex + 1}
                  {collapsedGroups.has(group.id) && (
                    <span className="group-summary">
                      ({group.filters.length} {group.filters.length === 1 ? "filtro" : "filtri"})
                    </span>
                  )}
                </div>
              </div>
              <button
                className="remove-group-button"
                onClick={() => removeGroup(group.id)}
                disabled={groups.length <= 1}
                title={groups.length <= 1 ? "Non è possibile eliminare l'unico gruppo" : "Elimina gruppo"}
              >
                <span>×</span> Elimina gruppo
              </button>
            </div>

            {/* Contenuto del gruppo - visibile solo se non è compresso */}
            <div className="group-content">
              {group.filters.map((filter, filterIndex) => (
                <div key={filter.id} className="filter-section">
                  <div className="filter-row">
                    <select className="filter-type-select" value={filter.type} onChange={(e) => updateFilter(group.id, filter.id, "type", e.target.value)}>
                      {Object.entries(FILTER_TYPES).map(([key, value]) => (
                        <option key={key} value={key}>
                          {value}
                        </option>
                      ))}
                    </select>

                    {/* Importo collezione */}
                    {filter.type === "COLLECTION_AMOUNT" && (
                      <div className="inline-inputs">
                        <select className="operator-select" value={filter.operator} onChange={(e) => updateFilter(group.id, filter.id, "operator", e.target.value)}>
                          <option value="GREATER_THAN">maggiore di</option>
                          <option value="LESS_THAN">minore di</option>
                          <option value="BETWEEN">tra</option>
                        </select>
                        {filter.operator === "BETWEEN" ? (
                          <div className="date-inputs">
                            <input
                              type="number"
                              className="value-input"
                              value={filter.valueStart}
                              onChange={(e) => updateFilter(group.id, filter.id, "valueStart", e.target.value)}
                            />
                            <span>e</span>
                            <input type="number" className="value-input" value={filter.valueEnd} onChange={(e) => updateFilter(group.id, filter.id, "valueEnd", e.target.value)} />
                          </div>
                        ) : (
                          <input
                            type="number"
                            className="value-input"
                            value={filter.value}
                            onChange={(e) => updateFilter(group.id, filter.id, "value", e.target.value)}
                            placeholder="Importo €"
                          />
                        )}
                      </div>
                    )}

                    {/* Importo speso per categoria */}
                    {filter.type === "CATEGORY_SPEND" && (
                      <div className="inline-inputs">
                        <select
                          className="category-select"
                          value={filter.category}
                          onChange={(e) => updateFilter(group.id, filter.id, "category", e.target.value)}
                          disabled={loadingCategories}
                        >
                          {loadingCategories ? (
                            <option disabled>Caricamento...</option>
                          ) : error ? (
                            <option disabled>Errore caricamento</option>
                          ) : (
                            categories.map((cat) => (
                              <option key={cat.id} value={cat.id}>
                                {cat.category_name}
                              </option>
                            ))
                          )}
                        </select>
                        <select className="operator-select" value={filter.operator} onChange={(e) => updateFilter(group.id, filter.id, "operator", e.target.value)}>
                          <option value="GREATER_THAN">maggiore di</option>
                          <option value="LESS_THAN">minore di</option>
                          <option value="BETWEEN">tra</option>
                        </select>
                        {filter.operator === "BETWEEN" ? (
                          <div className="date-inputs">
                            <input
                              type="number"
                              className="value-input"
                              value={filter.valueStart}
                              onChange={(e) => updateFilter(group.id, filter.id, "valueStart", e.target.value)}
                            />
                            <span>e</span>
                            <input type="number" className="value-input" value={filter.valueEnd} onChange={(e) => updateFilter(group.id, filter.id, "valueEnd", e.target.value)} />
                          </div>
                        ) : (
                          <input
                            type="number"
                            className="value-input"
                            value={filter.value}
                            onChange={(e) => updateFilter(group.id, filter.id, "value", e.target.value)}
                            placeholder="Importo €"
                          />
                        )}
                      </div>
                    )}

                    {/* Data primo acquisto */}
                    {filter.type === "FIRST_PURCHASE_DATE" && (
                      <div className="inline-inputs">
                        <select className="operator-select" value={filter.operator} onChange={(e) => updateFilter(group.id, filter.id, "operator", e.target.value)}>
                          <option value="EXACT_DATE">dal giorno</option>
                          <option value="DATE_RANGE">intervallo date</option>
                        </select>
                        {filter.operator === "DATE_RANGE" ? (
                          <div className="date-inputs">
                            <input
                              type="date"
                              className="value-input date"
                              value={filter.startDate}
                              onChange={(e) => updateFilter(group.id, filter.id, "startDate", e.target.value)}
                            />
                            <span>fino a</span>
                            <input type="date" className="value-input date" value={filter.endDate} onChange={(e) => updateFilter(group.id, filter.id, "endDate", e.target.value)} />
                          </div>
                        ) : (
                          <input type="date" className="value-input date" value={filter.value} onChange={(e) => updateFilter(group.id, filter.id, "value", e.target.value)} />
                        )}
                      </div>
                    )}
                    {filter.type === "CASHBACK_USAGE" && (
                      <div className="inline-inputs">
                        <select className="operator-select" value={filter.operator} onChange={(e) => updateFilter(group.id, filter.id, "operator", e.target.value)}>
                          <option value="EXACT_DATE">data precisa</option>
                        </select>
                        {<input type="date" className="value-input date" value={filter.value} onChange={(e) => updateFilter(group.id, filter.id, "value", e.target.value)} />}
                      </div>
                    )}

                    {/* Beni nei Preferiti / Beni posseduti */}
                    {(filter.type === "FAVORITE_ITEMS" || filter.type === "OWNED_ITEMS") && (
                      <div className="inline-inputs items-selector">
                        <Select
                          isMulti
                          name="items"
                          closeMenuOnSelect={false}
                          options={items}
                          className="items-multi-select"
                          classNamePrefix="select"
                          placeholder="Seleziona i prodotti..."
                          noOptionsMessage={() => "Nessun prodotto trovato"}
                          loadingMessage={() => "Caricamento..."}
                          isLoading={loadingItems}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          formatOptionLabel={(item) => (
                            <div className="item-option">
                              <img src={item.image} alt="" className="item-image" />
                              <span>{item.label}</span>
                            </div>
                          )}
                          value={selectedItemsMap[`${group.id}-${filter.id}`] || []}
                          onChange={(selectedOptions) => {
                            updateSelectedItems(group.id, filter.id, selectedOptions);
                          }}
                          onInputChange={(value) => {
                            if (value !== "") {
                              updateFilter(group.id, filter.id, "searchTerm", value);
                              debouncedSearch(value, group.id, filter.id);
                            }
                          }}
                          onFocus={() => {
                            // Quando il componente ottiene il focus, assicuriamoci di avere gli elementi selezionati
                            const mapKey = `${group.id}-${filter.id}`;
                            if (!selectedItemsMap[mapKey] && filter.selectedItems && filter.selectedItems.length > 0) {
                              // Se abbiamo elementi selezionati nel filtro ma non nella mappa, li recuperiamo
                              const selectedOptions = items.filter((item) => filter.selectedItems.includes(item.value));
                              if (selectedOptions.length > 0) {
                                setSelectedItemsMap((prev) => ({
                                  ...prev,
                                  [mapKey]: selectedOptions,
                                }));
                              } else {
                                // Se non troviamo gli elementi selezionati nelle opzioni attuali, ricarichiamo gli elementi
                                fetchItems("", group.id, filter.id);
                              }
                            } else if (items.length === 0) {
                              // Se non ci sono elementi caricati, li carichiamo
                              fetchItems("", group.id, filter.id);
                            }
                          }}
                          filterOption={(option, inputValue) => {
                            // Mostra sempre gli elementi selezionati
                            const mapKey = `${group.id}-${filter.id}`;
                            const selectedItems = selectedItemsMap[mapKey] || [];
                            const isSelected = selectedItems.some((item) => item.value === option.value);

                            if (isSelected) return true;

                            // Per il resto degli elementi, utilizziamo la ricerca server-side
                            // quindi non filtriamo qui
                            return true;
                          }}
                        />
                      </div>
                    )}

                    <button className="close-button" onClick={() => removeFilter(group.id, filter.id)}>
                      ×
                    </button>
                  </div>
                </div>
              ))}

              <button className="add-button" onClick={() => addFilter(group.id)}>
                <span>+</span> E
              </button>
            </div>
          </div>

          {/* {groupIndex < groups.length - 1 && (
            <div className="or-separator-container">
              <div className="or-separator">O</div>
            </div>
          )} */}
        </React.Fragment>
      ))}

      <button className="add-button" onClick={addGroup}>
        <span>+</span> O
      </button>

      <div className="pagination-settings">
        <div className="settings-header">
          <h3>Impostazioni di paginazione e ordinamento</h3>
        </div>
        <div className="settings-content">
          <div className="settings-row">
            <div className="settings-field">
              <label htmlFor="userLimit">Numero di utenti da estrarre:</label>
              <input
                id="userLimit"
                type="number"
                min="1"
                max="10000"
                className="value-input"
                value={userLimit}
                onChange={(e) => setUserLimit(Math.min(10000, Math.max(1, parseInt(e.target.value) || 1)))}
              />
              <span className="field-description">Numero massimo di utenti da recuperare</span>
            </div>

            <div className="settings-field">
              <label htmlFor="userSkip">A partire dall'utente numero:</label>
              <input id="userSkip" type="number" min="0" className="value-input" value={userSkip} onChange={(e) => setUserSkip(Math.max(0, parseInt(e.target.value) || 0))} />
              <span className="field-description">Numero di utenti da saltare (offset)</span>
            </div>
          </div>

          {/* Aggiungiamo le impostazioni di ordinamento */}
          <div className="settings-divider"></div>

          <div className="settings-row">
            <div className="settings-field">
              <label htmlFor="sortField">Ordinamento:</label>
              <select id="sortField" className="value-input" value={sortField} onChange={handleSortFieldChange}>
                {Object.entries(SORT_FIELDS).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
              <span className="field-description">Campo in base al quale ordinare i risultati</span>
            </div>

            <div className="settings-field">
              <label htmlFor="sortDirection">Direzione:</label>
              <select id="sortDirection" className="value-input" value={sortDirection} onChange={(e) => setSortDirection(e.target.value)} disabled={!sortField}>
                {Object.entries(SORT_DIRECTIONS).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
              <span className="field-description">Ordinamento crescente o decrescente</span>
            </div>
          </div>

          {/* Mostriamo la selezione della categoria solo se abbiamo selezionato ordinamento per categoria */}
          {sortField === "CATEGORY_SPEND" && (
            <div className="settings-row mt-2">
              <div className="settings-field">
                <label htmlFor="sortCategory">Categoria:</label>
                <select id="sortCategory" className="value-input" value={sortCategory} onChange={(e) => setSortCategory(e.target.value)} disabled={loadingCategories}>
                  {loadingCategories ? (
                    <option disabled>Caricamento...</option>
                  ) : error ? (
                    <option disabled>Errore caricamento</option>
                  ) : (
                    categories.map((cat) => (
                      <option key={cat.id} value={cat.id}>
                        {cat.category_name}
                      </option>
                    ))
                  )}
                </select>
                <span className="field-description">Categoria in base alla quale ordinare la spesa</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="footer">
        <div className="actions">
          <button className="cancel-button" onClick={handleClear}>
            Cancella filtri
          </button>
          <button className="search-button" onClick={handleSearch} disabled={isSearching}>
            {isSearching ? "Ricerca in corso..." : "Cerca utenti"}
          </button>
        </div>
      </div>

      {/* Visualizza i risultati della ricerca con MaterialReactTable */}
      {searchResults && (
        <div className="search-results-container">
          <div className="box">
            <div className="box-header with-border">
              <h4 className="box-title">Risultati ({tableData.length} utenti trovati)</h4>
              <div className="grid grid-cols-2 gap-2">
                {tableData.length > 0 && (
                  <button className="btn btn-sm btn-success me-2" onClick={() => setShowCreateListModal(true)} variant="contained" color="success">
                    Crea Lista
                  </button>
                )}
                {csvData.length > 0 && (
                  <CSVLink data={csvData} filename={`risultati_${new Date().toISOString().slice(0, 10)}.csv`} className="btn btn-sm btn-success ">
                    Esporta CSV
                  </CSVLink>
                ) }
              </div>
            </div>
            <div className="box-body">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={tableTheme}>
                  <MaterialReactTable table={table} />
                </ThemeProvider>
              </LocalizationProvider>
            </div>
          </div>
        </div>
      )}

      {/* Modale per la creazione della lista */}
      <Dialog
        open={showCreateListModal}
        onClose={() => !isCreatingList && setShowCreateListModal(false)}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            background: "#1d2330",
            borderRadius: "8px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.3)",
          },
        }}
      >
        <DialogTitle
          sx={{
            borderBottom: "1px solid rgba(255,255,255,0.1)",
            padding: "20px 24px",
            color: "#fff",
            fontSize: "1.25rem",
            fontWeight: 500,
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <i className="fa fa-list" style={{ color: "#4CAF50" }}></i>
          Crea Nuova Lista
        </DialogTitle>
        <DialogContent sx={{ padding: "24px" }}>
          <div style={{ marginBottom: "20px" }}>
            <div
              style={{
                background: "rgba(76, 175, 80, 0.1)",
                padding: "12px",
                borderRadius: "6px",
                marginBottom: "16px",
                marginTop: "16px",
              }}
            >
              <div
                style={{
                  color: "#4CAF50",
                  fontSize: "0.9rem",
                  marginBottom: "8px",
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                <i className="fa fa-info-circle"></i>
                Informazioni sulla lista
              </div>
              <div
                style={{
                  color: "#a2a4a9",
                  fontSize: "0.85rem",
                  lineHeight: "1.5",
                }}
              >
                <p>• La lista conterrà {tableData.length} utenti</p>
                <p>• Gli utenti sono filtrati secondo i criteri di ricerca impostati</p>
              </div>
            </div>
          </div>
          {modalError && (
            <div
              style={{
                background: "rgba(244, 67, 54, 0.1)",
                border: "1px solid rgba(244, 67, 54, 0.2)",
                borderRadius: "4px",
                padding: "12px",
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <i className="fa fa-exclamation-circle" style={{ color: "#f44336" }}></i>
              <span style={{ color: "#f44336", fontSize: "0.9rem" }}>{modalError}</span>
            </div>
          )}
          <TextField
            autoFocus
            margin="dense"
            label="Nome Lista"
            type="text"
            fullWidth
            variant="outlined"
            value={listName}
            onChange={(e) => {
              setListName(e.target.value);
              setModalError(null); // Rimuovi l'errore quando l'utente inizia a digitare
            }}
            disabled={isCreatingList}
            error={!!modalError}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: modalError ? "rgba(244, 67, 54, 0.5)" : "rgba(255,255,255,0.1)",
                },
                "&:hover fieldset": {
                  borderColor: modalError ? "rgba(244, 67, 54, 0.7)" : "rgba(255,255,255,0.2)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: modalError ? "#f44336" : "#4CAF50",
                },
                color: "#fff",
                "& input": {
                  color: "#fff",
                },
              },
              "& .MuiInputLabel-root": {
                color: modalError ? "#f44336" : "#a2a4a9",
                "&.Mui-focused": {
                  color: modalError ? "#f44336" : "#4CAF50",
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "16px 24px",
            borderTop: "1px solid rgba(255,255,255,0.1)",
            gap: "12px",
          }}
        >
          <Button
            onClick={() => {
              setShowCreateListModal(false);
              setModalError(null);
            }}
            disabled={isCreatingList}
            sx={{
              color: "#a2a4a9",
              "&:hover": {
                background: "rgba(255,255,255,0.05)",
              },
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={handleCreateList}
            disabled={isCreatingList || !listName.trim()}
            variant="contained"
            sx={{
              background: "#4CAF50",
              "&:hover": {
                background: "#45a049",
              },
              "&.Mui-disabled": {
                background: "rgba(76, 175, 80, 0.5)",
              },
            }}
          >
            {isCreatingList ? (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                Creazione in corso...
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <i className="fa fa-save"></i>
                Crea Lista
              </div>
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default QueryBuilderV2;
