import React, { useEffect } from "react";
import { Page, Text, View, Document, StyleSheet, Image } from "@react-pdf/renderer";
import CodiceFiscale from "codice-fiscale-js";
import firma from "../assets/firma.png"; // Assicurati che il percorso sia corretto

const styles = StyleSheet.create({
  page: {
    padding: 50,
    fontSize: 12,
    fontFamily: "Helvetica",
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
  },
  content: {
    lineHeight: 1.6,
    textAlign: "justify",
  },
  paragraph: {
    marginBottom: 10,
  },
  bulletList: {
    marginBottom: 10,
  },
  bulletItem: {
    flexDirection: "row",
    marginBottom: 5,
    paddingLeft: 10,
  },
  bulletPoint: {
    width: 10,
    marginRight: 5,
  },
  amount: {
    marginVertical: 10,
    fontSize: 12,
  },
  signatures: {
    marginTop: 50,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signatureBox: {
    width: "40%",
  },
  signatureLine: {
    borderTop: 1,
    borderColor: "black",
    marginTop: 80,
    marginBottom: 10,
  },
  signatureImage: {
    width: 150,
    marginTop: 20,
    marginBottom: 10,
  },
});

export const ReceiptDocument = ({ lot, supplierDetails }) => {

  if (!lot || !lot.paid || !lot.id || !lot.payment_date || !lot.price) {
    return null;
  }
  const paymentDate = lot?.payment_date ? new Date(lot.payment_date).toLocaleDateString("it-IT") : "_________________";
  const totalAmount = lot.price;

  // Estrai la data di nascita dal codice fiscale
  let birthDate = "_________________";
  if (supplierDetails?.fiscal_code) {
    try {
      if (CodiceFiscale.check(supplierDetails.fiscal_code)) {
        const cf = new CodiceFiscale(supplierDetails.fiscal_code.toUpperCase());
        birthDate = cf.birthday.toLocaleDateString("it-IT");
      }
    } catch (error) {
      console.error("Errore nel parsing del codice fiscale:", error);
      birthDate = "_________________";
    }
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Text>
            RICEVUTA n. {lot.id} del {paymentDate}
          </Text>
        </View>

        <View style={styles.content}>
          <Text style={styles.paragraph}>
            Il/La Sig./ra {supplierDetails?.name || "_________________"} nato/a in {supplierDetails?.nationality || "_________________"} il {birthDate} e residente in{" "}
            {supplierDetails?.city || "_________________"} {supplierDetails?.street || "_________________"} n {supplierDetails?.street_number || "__"} con CF{" "}
            {supplierDetails?.fiscal_code || "_________________"} (in seguito il "Venditore")
          </Text>

          <Text style={styles.paragraph}>riceve in data odierna</Text>

          <Text style={styles.paragraph}>
            da parte della Collecto srl con sede legale in Milano Via Paleocapa n 6 e registrata presso la camera di commercio di Milano con partita IVA 13062490969 (in seguito
            "Collecto"), per conto della società stessa o di terzi da cui la società ha ricevuto mandato,
          </Text>

          <Text style={styles.amount}>Euro {totalAmount ? parseFloat(totalAmount).toFixed(2) : "0.00"}</Text>

          <Text style={styles.paragraph}>a fronte della cessione definitiva del bene:</Text>

          <Text style={styles.paragraph}>{lot.description}</Text>

          <Text style={styles.paragraph}>Di tale bene il Cedente certifica:</Text>

          <View style={styles.bulletList}>
            <View style={styles.bulletItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>di essere il legittimo proprietario;</Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>che esso è libero da qualsiasi vincolo o gravame;</Text>
            </View>
            <View style={styles.bulletItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>che esso è autentico in ogni sua parte e che non vi sono elementi che possano comprometterne l'integrità o l'originalità.</Text>
            </View>
          </View>

          <Text style={styles.paragraph}>
            Il Cedente, inoltre, si impegna a fornire eventuali certificazioni di autenticità, se disponibili, a supporto della garanzia fornita anche in seguito alla presente
            ricevuta.
          </Text>
        </View>

        <View style={styles.signatures}>
          <View style={styles.signatureBox}>
            <Text>Il Cedente</Text>
            <View style={styles.signatureLine} />

            <Text>{supplierDetails?.name}</Text>
          </View>
          <View style={styles.signatureBox}>
            <Text>Collecto srl</Text>
            <Image source={firma} style={styles.signature} />
          </View>
        </View>
      </Page>
    </Document>
  );
};
