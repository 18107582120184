import "react-querybuilder/dist/query-builder.css";
import Footer from "../directives/footer";
import Header from "../directives/header";
import { Toaster } from "react-hot-toast";
import Sidebar from "../directives/sidebar";
import QueryBuilderV2 from "./QueryBuilderV2";

export const QueryBuilderPage = () => {

  return (
    <>
      <div className="wrapper">
        {/* <div id="loader"></div> */}
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {/* Main content */}
            <section className="content">
              <div className="row">
                <div className="col-lg-12 col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Crea liste trincea</h4>
                    </div>
                    <QueryBuilderV2 />
                    
                  </div>
                </div>
              </div>
            </section>
            {/* /.content */}
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default QueryBuilderPage;
