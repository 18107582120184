import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import {
  getuserguideAction,
  addUserGuideAction,
  editUserGuideAction,
  deleteUserGuideAction
} from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const UserGuide = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setuserDetails] = useState({});
  const [adduserDetails, setadduserDetails] = useState({});
  
  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getAssistance_request();
  }, []);

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setadduserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const getAssistance_request = async () => {
    setIsLoading(true);
    let res = await getuserguideAction();
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  function editPartner(item) {
    setuserDetails(item);
  }

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "heading",
        header: "Heading",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "heading_en",
        header: "Heading (EN)",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "content",
        header: "content",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.content == null ? "" : row.original.content;
        },
      },
      {
        accessorKey: "content_en",
        header: "content (EN)",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.content_en == null ? "" : row.original.content_en;
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <button
                type="button"
                onClick={() => editPartner(row.original)}
                className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                name={row.original.id}
                value={row.original.id}
              >
                Edit
              </button>
              <br />
              <br />
              <button
                type="button"
                onClick={() => DeleteUserGuide(row.original)}
                className="btn btn-primary btn-sm"
                name={row.original.id}
                value={row.original.id}
              >
                Delete
              </button>
            </>
          );
        },
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "date",
        Cell: ({ row }) => {
          return moment(row.original.datetime).format("YYYY-MM-DD");
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  const updatecontent = async (e) => {
    e.preventDefault();
    let res = await editUserGuideAction(userDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName('closeModal')[0].click();
      getAssistance_request();
    } else {
      toast.error(res.msg);
    }
  };

  const addUserGuide = async (e) => {
    e.preventDefault();
    console.log("adduserDetails", adduserDetails);
    let res = await addUserGuideAction(adduserDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName('closeModal')[0].click();
      getAssistance_request();
    } else {
      toast.error(res.msg);
    }
  };

  const DeleteUserGuide = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Assistant Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteUserGuideAction({ id: id });
        if (res.success) {
          getAssistance_request();
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">User Guide List</h4>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                          >
                            Add
                          </button>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/*Edit Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            content
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <form onSubmit={updatecontent}>
                          <div className="modal-body">
                            <div className="container">
                            <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Heading
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter Title"
                                  onChange={inputHandler}
                                  name="heading"
                                  value={userDetails.heading}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Heading (EN)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  placeholder="Enter Title"
                                  onChange={inputHandler}
                                  name="heading_en"
                                  value={userDetails.heading_en}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  Content
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  onChange={inputHandler}
                                  name="content"
                                  value={userDetails.content}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea2"
                                  className="form-label"
                                >
                                  Content
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea2"
                                  rows={3}
                                  onChange={inputHandler}
                                  name="content_en"
                                  value={userDetails.content_en}
                                />
                              </div>

                              <div className="modal-footer mt-20">
                                <button type="submit" className="btn btn-primary">
                                  submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary closeModal"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                  {/*Add Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Add Content
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <form onSubmit={addUserGuide}>
                          <div className="modal-body">
                            <div className="container">
                            <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Add Heading
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter Heading"
                                  onChange={inputHandler1}
                                  name="heading"
                                  value={adduserDetails.heading}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput2"
                                  className="form-label"
                                >
                                  Add Heading (EN)
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput2"
                                  placeholder="Enter Heading"
                                  onChange={inputHandler1}
                                  name="heading_en"
                                  value={adduserDetails.heading_en}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  Add Content
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  onChange={inputHandler1}
                                  placeholder="Enter Content"
                                  name="content"
                                  value={adduserDetails.content}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea2"
                                  className="form-label"
                                >
                                  Add Content (EN)
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea2"
                                  rows={3}
                                  onChange={inputHandler1}
                                  placeholder="Enter Content"
                                  name="content_en"
                                  value={adduserDetails.content_en}
                                />
                              </div>

                              <div className="modal-footer mt-20">
                                <button type="submit" className="btn btn-primary">
                                  submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary closeModal"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default UserGuide;
