import React, { useEffect, useState, useCallback, useMemo } from 'react'
import config from '../coreFIles/config';
import { Toaster } from 'react-hot-toast';
import Header from '../directives/header';
import Footer from '../directives/footer';
import Sidebar from '../directives/sidebar';
import { getReferrals, getUsersLiquidity, setUsersLiquidity, setReferralBonus } from '../Action/action';
import Swal from 'sweetalert2';
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Referrals = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // Table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
      pageIndex: 0,
      pageSize: 10,
    });

    //Liquidity Modal
    const [item, setItem] = useState({});
    const [error, setError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [saving, setsaving] = useState(false);
    const [lqiAmt, setLqiAmt] = useState(0);
    const [validatioError, setvalidatioError] = useState({});

    const getUsersList = useCallback(async () => {
        setIsLoading(true);
        let res = await getReferrals();
        if (res.success) {
            setData(res.data);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        getUsersList();
    }, [getUsersList]);

    const tableTheme = useMemo(
      () =>
        createTheme({
          palette: {
            mode: "dark",
          },
        }),
      []
    );

    const columns = useMemo(
      () => [
        {
          accessorKey: "id",
          header: "Id Transaction",
          enableSorting: false,
          enableColumnFilter: true,
        },
        {
          accessorKey: "referrer_email",
          header: "From User",
          enableSorting: false,
          enableColumnFilter: true,
          Cell: ({ row }) => {
            return (
              <>
                <strong>Name:</strong> {row.original.referrer_name} <br />
                <strong>Email:</strong> {row.original.referrer_email} <br />
              </>
            );
          },
        },
        {
          accessorKey: "referred_email",
          header: "To User",
          enableSorting: false,
          enableColumnFilter: true,
          Cell: ({ row }) => {
            return (
              <>
                <strong>Name:</strong> {row.original.referred_name} <br />
                <strong>Email:</strong> {row.original.referred_email} <br />
              </>
            );
          },
        },
        {
          accessorKey: "status",
          header: "Referral Status",
          enableSorting: false,
          enableColumnFilter: true,
          filterVariant: "select",
          filterSelectOptions: [
            { text: "Waiting First Purchase", value: "waitingFirstPurchase" },
            { text: "Waiting Admin Bonus", value: "waitingAdminBonus" },
            { text: "Bonus Received", value: "bonusReceived" },
          ],
          Cell: ({ row }) => {
            return (
              <>
                {row.original.status === "waitingFirstPurchase" && "Waiting First Purchase"}
                {row.original.status === "waitingAdminBonus" && "Waiting Admin Bonus"}
                {row.original.status === "bonusReceived" && "Bonus Received"}
              </>
            );
          },
        }
      ],
      []
    );

    const table = useMaterialReactTable({
      columns,
      data,
      enableRowSelection: false,
      enableRowNumbers: false,
      initialState: { showColumnFilters: true },
      manualFiltering: false,
      manualPagination: false,
      manualSorting: false,
      enableGlobalFilter: false,
      onColumnFiltersChange: setColumnFilters,
      onGlobalFilterChange: setGlobalFilter,
      onPaginationChange: setPagination,
      onSortingChange: setSorting,
      mrtTheme: (theme) => ({
        baseBackgroundColor: "#1d2330",
        oddRowBackgroundColor: "#1d2330",
        headerBackgroundColor: "#131720",
        draggingBorderColor: "#d1d3e0",
      }),
      muiTableHeadProps: {
        sx: {
          border: "0.1px solid #ffffff1f",
          backgroundColor: "#141820",
        },
      },
      muiTableHeadCellProps: {
        sx: {
          backgroundColor: "#141820",
          textAlign: "center",
          fontSize: "14px",
        },
      },
      muiTableBodyCellProps: {
        sx: {
          border: "1px solid #ffffff1f",
          fontSize: "14px",
          color: "#a2a4a9",
        },
      },
      muiTablePaperProps: {
        elevation: 0,
      },
      state: {
        columnFilters,
        globalFilter,
        isLoading,
        pagination,
        sorting,
      },
    });

    return (
        <>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={tableTheme}>
              <div className="wrapper">
                <Toaster />
                <Header />
                <Sidebar />
                <div className="content-wrapper">
                  <div className="container-full">
                    <section className="content">
                      <div className="row">
                        <div className="col-lg-12 col-12">
                          <div className="box">
                            <div className="box-header with-border">
                              <h4 className="box-title">Referrals</h4>
                            </div>
                            <div className="box-body">
                              <MaterialReactTable table={table} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <Footer />
              </div>
            </ThemeProvider>
          </LocalizationProvider>
        </>
    );
};

export default Referrals;