import React, { useState, useCallback } from 'react'
import config from '../coreFIles/config'
import Cookies from 'js-cookie'
import { getUsersV2ListAction, getItemListAction } from '../Action/action'
import GlobalSearchDropdown from '../components/GlobalSearchDropdown'
import debounce from 'lodash/debounce'
import '../assets/css/global-search.css'

const Header = () => {
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showDropdown, setShowDropdown] = useState(true)

  const loginData = (!Cookies.get('loginSuccessCollectoAdmin')) ? [] : JSON.parse(Cookies.get('loginSuccessCollectoAdmin'));
  if (!loginData || loginData === '') {
    window.location.href = `${config.baseUrl}`;
  }

  const handleSearch = useCallback(
    debounce(async (query) => {
      if (!query.trim()) {
        setSearchResults([]);
        return;
      }

      setIsLoading(true);
      try {
        // Cerca utenti per email
        const usersEmailResponse = await getUsersV2ListAction(0, 100, JSON.stringify([
          {
            id: 'email',
            value: query,
          }
        ]));

        // Cerca utenti per telefono
        const usersPhoneResponse = await getUsersV2ListAction(0, 100, JSON.stringify([
          {
            id: 'mobile_no',
            value: query,
          }
        ]));

        // Cerca tra gli items
        const itemsResponse = await getItemListAction(0, 100, JSON.stringify([
          {
            id: 'name',
            value: query,
          }
        ]));

        // Combina i risultati degli utenti e rimuovi i duplicati
        const usersMap = new Map();
        
        // Aggiungi utenti trovati per email
        usersEmailResponse.data.users.forEach(user => {
          usersMap.set(user.id, { ...user, type: 'user' });
        });

        // Aggiungi utenti trovati per telefono (sovrascrive se già presente)
        usersPhoneResponse.data.users.forEach(user => {
          usersMap.set(user.id, { ...user, type: 'user' });
        });

        // Converti la Map in array
        const users = Array.from(usersMap.values());

        const items = itemsResponse.data.items.map(item => ({
          ...item,
          type: 'item'
        }));

        setSearchResults([...users, ...items]);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    setShowDropdown(true);
    handleSearch(query);
  };

  const handleCloseDropdown = () => {
    setShowDropdown(false);
  };

  const handleResultSelect = (result) => {
    if (result.type === 'user') {
      window.location.href = `${config.baseUrl}userDetails?id=${result.id}`;
    } else {
      window.location.href = `${config.baseUrl}itemAdd/${result.id}`;
    }
  };

  const logout = async () => {
    Cookies.remove('loginSuccessCollectoAdmin');
    window.location.href = config.baseUrl;
  }

  return (
    <>
      <header className="main-header">
        <div className="d-flex align-items-center logo-box justify-content-start">
          {/* Logo */}
          <a href="dashboard" className="logo">
            {/* logo*/}
            <div className="logo-mini w-50">
              <span className="light-logo">
                <img src="./favicon.png" alt="logo" />
              </span>
              <span className="dark-logo">
                <img src="./favicon.png" alt="logo" />
              </span>
            </div>
            <div className="logo-lg">
              <span className="light-logo">
                <img src="./images/logo.png" alt="logo" />
              </span>
              <span className="dark-logo">
                <img src="./images/logo.png" alt="logo" />
              </span>
            </div>
          </a>
        </div>
        {/* Header Navbar */}
        <nav className="navbar navbar-static-top">
          {/* Sidebar toggle button*/}
          <div className="app-menu">
            <ul className="header-megamenu nav">
              <li className="btn-group nav-item">
                <a
                  href="/#"
                  className="waves-effect waves-light nav-link push-btn btn-primary-light"
                  data-toggle="push-menu"
                  role="button"
                >
                  <i data-feather="align-left" />
                </a>
              </li>
            </ul>
          </div>

          {/* Global Search */}
          <div className="global-search-container mx-4">
            <input
              type="text"
              className="global-search-input"
              placeholder="Search users or items..."
              value={searchQuery}
              onChange={handleSearchChange}
            />
            {showDropdown && (
              <GlobalSearchDropdown 
                results={searchResults}
                onSelect={handleResultSelect}
                isLoading={isLoading}
                onClose={handleCloseDropdown}
              />
            )}
          </div>

          <div className="navbar-custom-menu r-side">
            <ul className="nav navbar-nav">
              <li className="dropdown user user-menu">
                <a
                  href="/#"
                  className="waves-effect waves-light dropdown-toggle w-auto l-h-12 bg-transparent py-0 no-shadow"
                  data-bs-toggle="dropdown"
                  title="User"
                >
                  <div className="d-flex pt-5">
                    <div className="text-end me-10">
                      <p className="pt-5 fs-14 mb-0 fw-700 text-primary">{loginData.username}</p>
                      <small className="fs-10 mb-0 text-uppercase text-mute">
                        Admin
                      </small>
                    </div>
                    <img
                      src="./images/avatar/avatar-1.png"
                      className="avatar rounded-10 bg-primary-light h-40 w-40"
                      alt=""
                    />
                  </div>
                </a>
                <ul className="dropdown-menu animated flipInX">
                  <li className="user-body">
                    <a className="dropdown-item" href="#;" onClick={logout}>
                      <i className="ti-lock text-muted me-2" /> Logout
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header;
