import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getGiftCardsAction } from "../Action/action";
import moment from "moment";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const GiftCards = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getGiftCards();
  }, []);

  const getGiftCards = async () => {
    setIsLoading(true);
    let res = await getGiftCardsAction();
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "user.email",
        header: "Buyer",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          if (row.original.user.id == 1) {
            return row.original.payer_email;
          } else {
            return row.original.user.email;
          }
        },
      },
      {
        accessorKey: "amount",
        header: "Amount",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "status",
        header: "Status",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "select",
        filterSelectOptions: [
          { text: "Pending payment", value: "pending" },
          { text: "Paid", value: "success" },
          { text: "Assigned to user", value: "assigned" },
          { text: "Failed", value: "failed" },
        ],
        Cell: ({ row }) => {
          switch (row.original.status) {
            case "pending":
              return <span className="badge badge-warning">Pending payment</span>;
            case "success":
              return <span className="badge badge-info">Paid</span>;
            case "assigned":
              return <span className="badge badge-success">Assigned to user</span>;
            case "failed":
              return <span className="badge badge-danger">Failed</span>;
            default:
              return <span className="badge badge-danger">{row.original.status}</span>;
          }
        },
      },
      {
        accessorKey: "type",
        header: "Type",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return row.original.user.id == 1 ? "WEB" : "APP";
        },
      },
      {
        accessorKey: "payment_intent",
        header: "Payment Intent",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "phone_number",
        header: "User phone number",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return (
            row.original.country_code + row.original.phone_number
          );
        },
      },
      {
        accessorKey: "createdAt",
        header: "Created At",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "date",
        Cell: ({ row }) => {
          return moment(row.original.createdAt).utcOffset(2).format("DD-MM-YYYY HH:mm:ss");
        },
      }
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: false,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Gift cards List</h4>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="mt-2 fw-light font-size-sm text-danger">* Red Color Indicates Deleted Users</p>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default GiftCards;
