import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {
  getexpertlistAction,
  addexpertAction,
  editexpertAction,
  deleteexpertAction,
} from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const ExpertDetails = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setuserDetails] = useState({});
  const [adduserDetails, setadduserDetails] = useState({'expert_name':"", "description":"", "description_en":""});
  const [image_preview, setimage_preview] = useState("images/noimage.png");
  const [image_preview1, setimage_preview1] = useState("");
  
  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getexpertDetails();
  }, []);

  const partnerPic = async (e) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setimage_preview(image_as_base64);
    setadduserDetails((old) => {
      return { ...old, ["expert_image"]: image_as_files };
    });
  };

  const partnerPic1 = async (e) => {
    e.preventDefault();
    let image_as_base64 = URL.createObjectURL(e.target.files[0]);
    let image_as_files = e.target.files[0];
    setimage_preview1(image_as_base64);
    setuserDetails((old) => {
      return { ...old, ["new_expert_image"]: image_as_files };
    });
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setuserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setadduserDetails((old) => {
      return { ...old, [name]: value };
    });
  };

  const getexpertDetails = async () => {
    setIsLoading(true);
    let res = await getexpertlistAction();
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  function editPartner(item) {
    setuserDetails(item);
  }

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "expert_image",
        header: "Expert Image",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <a target="__blank" href={`${config.imageUrl}` + row.original.expert_image}>
                <img
                  src={`${config.imageUrl}` + row.original.expert_image}
                  width="70px"
                  height="70px" 
                  alt=""
                ></img>
              </a>
              <br />
            </>
          );
        },
      },
      {
        accessorKey: "expert_name",
        header: "Expert Name",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.description == null ? "" : row.original.description;
        },
      },
      {
        accessorKey: "description_en",
        header: "Description (EN)",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.description_en == null ? "" : row.original.description_en;
        },
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableSorting: false,
        enableColumnFilter: false,
        filterVariant: "date",
        Cell: ({ row }) => {
          return moment(row.original.datetime).format("YYYY-MM-DD");
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <button
                type="button"
                onClick={() => editPartner(row.original)}
                className="btn btn-primary btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                name={row.original.id}
                value={row.original.id}
              >
                Edit
              </button>
              <br />
              <br />
              <button
                type="button"
                onClick={() => DeleteUserGuide(row.original)}
                className="btn btn-primary btn-sm"
                name={row.original.id}
                value={row.original.id}
              >
                Delete
              </button>
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  const updatecontent = async (e) => {
    e.preventDefault();
    console.log("userDetails", userDetails);
    let res = await editexpertAction(userDetails);
    if (res.success) {
      toast.success(res.msg);
      document.getElementsByClassName("closeModal")[0].click();
      getexpertDetails();
    } else {
      toast.error(res.msg);
    }
  };

  const addExpert = async (e) => {
    e.preventDefault();
    console.log("adduserDetails", adduserDetails);
    if(!adduserDetails.expert_name || adduserDetails.expert_name == ""){
      toast.error('Expert name required!');
      return;
    }
    else if(!adduserDetails.description || adduserDetails.description == ""){
      toast.error('Expert description required!');
      return;
    }

    else if(!adduserDetails.description_en || adduserDetails.description_en == ""){
      toast.error('Expert description required (EN)!');
      return;
    }

    let res = await addexpertAction(adduserDetails);
    if (res.success) {
      toast.success(res.msg);
      setTimeout(() => {
        window.location.href = `${config.baseUrl}expertdetails`;
      }, 1000);
    } else {
      toast.error(res.msg);
    }
  };

  const DeleteUserGuide = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Assistant Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteexpertAction({ id: id });
        if (res.success) {
          getexpertDetails();
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Toaster />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Expert List</h4>
                          <button
                            type="button"
                            className="btn btn-primary btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal1"
                          >
                            Add
                          </button>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {/*Edit Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            description
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <form onSubmit={updatecontent}>
                          <div className="modal-body">
                            <div className="container">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                  Expert Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter Title"
                                  onChange={inputHandler}
                                  name="expert_name"
                                  value={userDetails.expert_name}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                  Description
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  onChange={inputHandler}
                                  name="description"
                                  value={userDetails.description}
                                />
                              </div>
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea2"
                                  className="form-label"
                                >
                                  Description (EN)
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea2"
                                  rows={3}
                                  onChange={inputHandler}
                                  name="description_en"
                                  value={userDetails.description_en}
                                />
                              </div>
                              <div className="mb-3">
                                {image_preview1 === "" ? (
                                  userDetails?.new_expert_image === null ||
                                    userDetails?.new_expert_image === "null" ||
                                    userDetails?.new_expert_image === "" ? (
                                    ""
                                  ) : (
                                    <img
                                      style={{ height: "100px", width: "100px" }}
                                      className="object-cover w-full h-32"
                                      src={`${config.imageUrl}${userDetails?.new_expert_image}`}
                                      alt=""
                                    />
                                  )
                                ) : (
                                  <img
                                    style={{ height: "100px", width: "100px" }}
                                    id="image"
                                    className="object-cover w-full h-32"
                                    src={image_preview1} alt=""
                                  />
                                )}

                                <input
                                  name="new_expert_image"
                                  onChange={partnerPic1}
                                  id="fileInput"
                                  accept="image/*"
                                  className="form-control mt-5"
                                  type="file"
                                />
                              </div>

                              <div className="modal-footer mt-20">
                                <button type="submit" className="btn btn-primary">
                                  submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary closeModal"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  
                  {/*Add Modal */}
                  <div
                    className="modal fade"
                    id="exampleModal1"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalLabel">
                            Add Expert Details
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          />
                        </div>
                        <form onSubmit={addExpert}>
                          <div className="modal-body">
                            <div className="container">
                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlInput1"
                                  className="form-label"
                                >
                                   Expert Name
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="exampleFormControlInput1"
                                  placeholder="Enter expert_name"
                                  onChange={inputHandler1}
                                  name="expert_name"
                                  value={adduserDetails.expert_name}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                   Description
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  onChange={inputHandler1}
                                  placeholder="Enter description"
                                  name="description"
                                  value={adduserDetails.description}
                                />
                              </div>

                              <div className="mb-3">
                                <label
                                  htmlFor="exampleFormControlTextarea1"
                                  className="form-label"
                                >
                                   Description (EN)
                                </label>
                                <textarea
                                  className="form-control"
                                  id="exampleFormControlTextarea1"
                                  rows={3}
                                  onChange={inputHandler1}
                                  placeholder="Enter description (EN)"
                                  name="description_en"
                                  value={adduserDetails.description_en}
                                />
                              </div>
                              <div className="mb-3">
                                {image_preview === "" ? (
                                  adduserDetails?.expert_image === null ||
                                    adduserDetails?.expert_image === "null" ||
                                    adduserDetails?.expert_image === "" ? (
                                    <img
                                      style={{ height: "100px", width: "100px" }}
                                      className="object-cover w-full h-32"
                                      src=""
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      style={{ height: "100px", width: "100px" }}
                                      className="object-cover w-full h-32"
                                      src={`${config.imageUrl}${adduserDetails?.expert_image}`}
                                      alt=""
                                    />
                                  )
                                ) : (
                                  <img
                                    style={{ height: "100px", width: "100px" }}
                                    id="image"
                                    className="object-cover w-full h-32"
                                    src={image_preview} alt=""
                                  />
                                )}

                                <input
                                  name="expert_image"
                                  onChange={partnerPic}
                                  id="fileInput"
                                  accept="image/*"
                                  className="form-control mt-5"
                                  type="file"
                                />
                              </div>

                              <div className="modal-footer mt-20">
                                <button type="submit" className="btn btn-primary">
                                  submit
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-secondary closeModal1"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default ExpertDetails;
