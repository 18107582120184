import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import { deleteCategoryAction, getCategoryListAction } from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const Category = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  
  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getcategory();
  }, []);

  const getcategory = async () => {
    setIsLoading(true);
    let res = await getCategoryListAction();
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "category_name",
        header: "Category",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "datetime",
        header: "Date",
        enableSorting: false,
        enableColumnFilter: true,
        filterVariant: "date",
        Cell: ({ row }) => {
          return moment(row.original.datetime).format("YYYY-MM-DD");
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <a
                href={`${config.baseUrl}Categoryupdate/${row.original.id}`}
                className="btn btn-sm btn-primary"
                id="editbtnid"
              >
                Edit
              </a>{" "}
              &nbsp;
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => deleteCategory(row.original.id)}
              >
                Delete
              </button>{" "}
              &nbsp;
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  const deleteCategory = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "After deleting this category, the attributes are also deleted!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteCategoryAction({ id: id });
        if (res.success) {
          getcategory();
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Category List</h4>
                          <a
                            href={`${config.baseUrl}Addcategory`}
                            className="btn btn-sm btn-primary add_btn"
                          >
                            Add New
                          </a>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default Category;
