import React, { useEffect, useState, useMemo } from "react";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { Button, createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";
import moment from "moment";
import Swal from "sweetalert2";
import { Toaster } from "react-hot-toast";

import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { getBankTransfersAction, approveBankTransferAction } from "../Action/action";

const BankTransfersV2 = () => {
  // Data states
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  // Table states
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const handleApproveTransfer = async (transferId) => {
    try {
      Swal.fire({
        title: "Conferma approvazione",
        text: "Vuoi approvare questo bonifico?",
        icon: "warning", 
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, approva!"
      }).then(async (result) => {
        if (result.isConfirmed) {
          const res = await approveBankTransferAction(transferId);
          if (res.success) {
            Swal.fire("Approvato!", res.msg, "success");
            fetchData();
          } else {
            Swal.fire("Fallito!", res.msg, "error");
          }
        }
      });
    } catch (error) {
      console.error(error);
      Swal.fire("Failed!", "Something went wrong", "error");
    }
  };

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const filtersToUse = JSON.stringify(columnFilters ?? []);
      const response = await getBankTransfersAction(
        pagination.pageIndex + 1,
        pagination.pageSize,
        filtersToUse
      );

      if (response.success) {
        setData(response.data.transfers);
        setRowCount(response.data.pagination.total);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleCsvDownload = async () => {
    try {
      setCsvLoading(true);
      const filtersToUse = JSON.stringify(columnFilters ?? []);
      const response = await getBankTransfersAction(1, 10000, filtersToUse);
      
      if (response.success && response.data.transfers.length > 0) {
        const csvData = [
          ["ID", "Reference Code", "User Name", "Email", "Mobile", "Amount", "Status", "Date"],
          ...response.data.transfers.map((transfer) => [
            transfer.id,
            transfer.reference_code,
            transfer.user.name,
            transfer.user.email,
            transfer.user.mobile_no,
            transfer.amount,
            transfer.status,
            moment(transfer.created_at).format("DD/MM/YYYY HH:mm"),
          ]),
        ];
        setCsvData(csvData);
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Failed!", "Error downloading CSV", "error");
    } finally {
      setCsvLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnFilters, pagination.pageIndex, pagination.pageSize]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 60,
        enableColumnFilter: false,
      },
      {
        accessorKey: "reference_code",
        header: "Reference Code",
        size: 120,
        enableSorting: false,
      },
      {
        accessorKey: "user.email",
        header: "Email",
        size: 200,
        enableSorting: false,
      },
      {
        accessorKey: "user.mobile_no",
        header: "Mobile",
        size: 120,
        enableSorting: false,
      },
      {
        accessorKey: "amount",
        header: "Amount",
        size: 100,
        enableSorting: false,
        Cell: ({ cell }) => `€${Number(cell.getValue()).toFixed(2)}`,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 100,
        enableSorting: false,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          const colorMap = {
            pending: "warning",
            completed: "success",
            approved_by_admin: "success",
          };
          return <span className={`badge bg-${colorMap[status]}`}>{status}</span>;
        },
        filterVariant: "select",
        filterSelectOptions: ["pending", "completed", "approved_by_admin"],
      },
      {
        accessorKey: "created_at",
        header: "Date",
        size: 160,
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ cell }) => moment(cell.getValue()).format("DD/MM/YYYY HH:mm"),
        filterVariant: "date",
      },
      {
        accessorKey: "actions",
        header: "Actions",
        size: 200,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => {
          const transfer = row.original;
          return (
            transfer.status === "pending" && (
              <div>
                <button
                  onClick={() => handleApproveTransfer(transfer.id)}
                  type="button"
                  className="btn-sm btn-success m-2"
                >
                  Approva Bonifico
                </button>
              </div>
            )
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0
    },
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <Toaster />
          <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Bank Transfers</h4>
                          {csvLoading ? (
                            <div className="text-center">
                              <div className="spinner-border" role="status" aria-hidden="true"></div>
                            </div>
                          ) : csvData.length > 0 ? (
                            <CSVLink data={csvData} filename="BankTransfers.csv" className="btn btn-sm btn-primary">
                              Export CSV
                            </CSVLink>
                          ) : (
                            <Button className="btn btn-sm btn-primary" onClick={handleCsvDownload}>
                              Load CSV
                            </Button>
                          )}
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default BankTransfersV2; 