import { Box, Grid, TextField, Typography, Button, Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import config from "../coreFIles/config";
import moment from "moment";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { editUserDataAction, getUserDetailsByIdAction, getUsersLiquidity, setUsersLiquidity } from "../Action/action";

const UserDetails = ({ userDetails,setUserDetails }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(false);
  const [saving, setSaving] = useState(false);
  const [lqiAmt, setLqiAmt] = useState(0);
  const [isTransfer, setIsTransfer] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [modalItem, setModalItem] = useState({});
  const [formData, setFormData] = useState({
    name: userDetails.user.name || "",
    cognome: userDetails.user.cognome || "",
    email: userDetails.user.email || "",
    country_code: userDetails.user.country_code || "",
    mobile_no: userDetails.user.mobile_no || "",
    date_of_birth: userDetails.user.date_of_birth || "",
    place_of_birth: userDetails.user.place_of_birth || "",
    fiscal_code: userDetails.user.fiscal_code || "",
    nickname: userDetails.user.nickname || "",
    city: userDetails.user.city || "",
    gender: userDetails.user.gender == "Uomo" ? "Male" : userDetails.user.gender || "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value === "Uomo" ? "Male" : value,
    }));
  };

  const handleSubmit = async () => {
    try {
      const result = await editUserDataAction({
        userId: userDetails.user.id,
        data: formData,
      });

      if (result.success) {
        toast.success("Dettagli utente aggiornati con successo");
        const updatedDetails = await getUserDetailsByIdAction(userDetails.user.id);
        if (updatedDetails) {
          setUserDetails(updatedDetails.data);
        }
        setIsEditing(false);
      } else {
        toast.error(result.msg || "Impossibile aggiornare i dettagli dell'utente");
      }
    } catch (error) {
      console.error("Errore durante l'aggiornamento dei dettagli:", error);
      toast.error("Impossibile aggiornare i dettagli dell'utente");
    }
  };

  const handleLiquidityModal = async (user) => {
    try {
      const res = await getUsersLiquidity({ user_id: user.id });
      if (res.success) {
        setModalItem({
          ...user,
          liquidity_amount: res.liquidity_amount,
          withdrawable_liquidity_amount: res.withdrawable_liquidity_amount,
          primary_bonus_liquidity_amount: res.primary_bonus_liquidity_amount,
          secondary_bonus_liquidity_amount: res.secondary_bonus_liquidity_amount,
        });
        setShowModal(true);
      } else {
        setModalItem(user);
        throw new Error("Something went wrong while fetching liquidity");
      }
    } catch (err) {
      console.log(err);
      setError(true);
      setShowModal(true);
    }
  };

  const handleLiquiditySubmit = async (e) => {
    e.preventDefault();

    if (lqiAmt === 0 || lqiAmt === null || lqiAmt === undefined || lqiAmt === "") {
      setValidationError({ ...validationError, lqiAmt: true });
      return;
    }

    setValidationError({});
    setSaving(true);
    try {
      const res = await setUsersLiquidity({ 
        user_id: modalItem.id, 
        bonus_liquidity: lqiAmt, 
        is_bank_transfer: isTransfer 
      });
      
      if (res.success) {
        toast.success(res.msg);
        const updatedDetails = await getUserDetailsByIdAction(userDetails.user.id);
        if (updatedDetails) {
          setUserDetails(updatedDetails.data);
        }
      } else {
        toast.error(res.msg);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong");
    } finally {
      setShowModal(false);
      setSaving(false);
      setIsTransfer(false);
    }
  };

  const editableFields = [
    { name: "name", label: "Nome" },
    { name: "cognome", label: "Cognome" },
    { name: "email", label: "Email" },
    { name: "country_code", label: "Prefisso Internazionale" },
    { name: "mobile_no", label: "Numero di Telefono" },
    { name: "date_of_birth", label: "Data di Nascita", type: "date" },
    { name: "place_of_birth", label: "Luogo di Nascita" },
    { name: "fiscal_code", label: "Codice Fiscale" },
    { name: "nickname", label: "Nickname" },
    { name: "city", label: "Città" },
  ];

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <h3>Dettagli Utente</h3>
        <Box>
          {!isEditing ? (
            <>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => setIsEditing(true)}
                sx={{ mr: 1 }}
              >
                Modifica Dettagli
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleLiquidityModal(userDetails.user)}
              >
                Liquidità
              </Button>
            </>
          ) : (
            <Box>
              <Button variant="contained" color="primary" onClick={handleSubmit} sx={{ mr: 1 }}>
                Salva
              </Button>
              <Button variant="outlined" onClick={() => setIsEditing(false)}>
                Annulla
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} style={{ display: "flex", justifyContent: "center", marginBottom: "20px" }}>
          {userDetails.user.signup_by === "GMAIL" || userDetails.user.signup_by === "FACEBOOK" || userDetails.user.signup_by === "APPLE" ? (
            parseInt(userDetails.user.profile_change_stt) === 1 ? (
              userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
                <img
                  src="./images/avatar-2.png"
                  className="bg-primary-light "
                  alt=""
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <a target="__blank" href={`${config.imageUrl}${userDetails.user.profile_pic}`}>
                  <img
                    src={`${config.imageUrl}${userDetails.user.profile_pic}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "50px",
                    }}
                    alt=""
                  />
                </a>
              )
            ) : userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
              <img
                src="./images/avatar-2.png"
                className="bg-primary-light "
                alt=""
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
              />
            ) : (
              <a target="__blank" href={userDetails.user.profile_pic}>
                <img
                  src={userDetails.user.profile_pic}
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50px",
                  }}
                  alt=""
                />
              </a>
            )
          ) : userDetails.user.profile_pic === 0 || userDetails.user.profile_pic === null || userDetails.user.profile_pic === "" ? (
            <img src="./images/avatar-2.png" className="bg-primary-light " alt="" style={{ width: "100px", height: "100px", borderRadius: "50px" }} />
          ) : (
            <a target="__blank" href={`${config.imageUrl}${userDetails.user.profile_pic}`}>
              <img
                src={`${config.imageUrl}${userDetails.user.profile_pic}`}
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50px",
                }}
                alt=""
              />
            </a>
          )}
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, p: 2, borderRadius: 2, border: "1px solid #5a5f66" }}>
            <Box>
              <Typography variant="h6" gutterBottom>
                Liquidità Utente
              </Typography>
              <Typography variant="body1">Totale: {Number(userDetails.user.liquidity_amount + userDetails.user.primary_bonus_liquidity).toFixed(2)}</Typography>
              <Typography variant="body1">Prelevabile: {Number(userDetails.user.withdrawable_liquidity_amount).toFixed(2)}</Typography>
              <Typography variant="body1">Liquidità Bonifico: {Number(userDetails.user.bank_transfer_liquidity).toFixed(2)}</Typography>
              <Typography variant="body1">Liquidità Bonus: {Number(userDetails.user.bonus_liquidity).toFixed(2)}</Typography>
              <Typography variant="body1">Liquidità Bonus Primario: {Number(userDetails.user.primary_bonus_liquidity).toFixed(2)}</Typography>
              <Typography variant="body1">Liquidità Bonus Secondario: {Number(userDetails.user.secondary_bonus_liquidity).toFixed(2)}</Typography>
            </Box>
          </Box>
        </Grid>
        {editableFields.map((field) => (
          <Grid item xs={12} sm={6} key={field.name}>
            <TextField
              fullWidth
              label={field.label}
              name={field.name}
              type={field.type || "text"}
              value={isEditing 
                ? (field.name === "date_of_birth" && formData[field.name]
                  ? moment(formData[field.name]).format("YYYY-MM-DD")
                  : formData[field.name])
                : (field.name === "date_of_birth" && userDetails.user[field.name]
                  ? moment(userDetails.user[field.name]).format("YYYY-MM-DD")
                  : userDetails.user[field.name]) || ""}
              onChange={handleInputChange}
              disabled={!isEditing}
              variant="outlined"
              margin="normal"
              InputLabelProps={field.type === "date" ? { shrink: true } : undefined}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="gender-label">Genere</InputLabel>
            <Select
              labelId="gender-label"
              name="gender"
              value={isEditing ? formData.gender : userDetails.user.gender == "Uomo" ? "Male" : userDetails.user.gender || ""}
              onChange={handleInputChange}
              disabled={!isEditing}
              label="Genere"
            >
              <MenuItem value="Male">Maschio</MenuItem>
              <MenuItem value="Female">Femmina</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="ID" value={userDetails.user.id} disabled variant="outlined" margin="normal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Data di Creazione" value={moment(userDetails.user.created_at).format("YYYY-MM-DD HH:mm:ss")} disabled variant="outlined" margin="normal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Signup By" value={userDetails.user.signup_by !== "NORMAL" ? userDetails.user.signup_by : "Phone Number"} disabled variant="outlined" margin="normal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Web2 Wallet" value={userDetails.user.web2_wallet || "N/A"} disabled variant="outlined" margin="normal" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth label="Referral Code" value={userDetails.user.referral_code || "N/A"} disabled variant="outlined" margin="normal" />
        </Grid>
        {userDetails.user.id_document_front && userDetails.user.id_document_back && (
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Documenti Utente
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <a href={config.imageUrl + userDetails.user.id_document_front} target="_blank" rel="noreferrer">
                  <img src={config.imageUrl + userDetails.user.id_document_front} alt="Fronte Documento" style={{ maxWidth: "100%", height: "auto" }} />
                </a>
                <Typography variant="subtitle1" align="center">
                  Fronte Documento
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <a href={config.imageUrl + userDetails.user.id_document_back} target="_blank" rel="noreferrer">
                  <img src={config.imageUrl + userDetails.user.id_document_back} alt="Retro Documento" style={{ maxWidth: "100%", height: "auto" }} />
                </a>
                <Typography variant="subtitle1" align="center">
                  Retro Documento
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Modale Liquidità */}
      {showModal && (
        <div className="modal fade show" id="liquidityModal" tabIndex={-1} aria-labelledby="liquidityModalLabel" aria-hidden="true" style={{ display: "block" }}>
          <div className="modal-dialog">
            <div className="modal-content">
              {Object.keys(modalItem).length > 0 ? (
                <>
                  <div className="modal-header" data-bs-theme="dark">
                    <h5 className="modal-title" id="liquidityModalLabel">
                      Liquidità per {modalItem.name} {modalItem.cognome}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => setShowModal(false)} />
                  </div>
                  {error ? (
                    <div className="modal-body">
                      <div className="container">
                        <div className="mb-3">
                          <p className="text-center">Qualcosa è andato storto. Per favore riprova più tardi</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <form onSubmit={handleLiquiditySubmit}>
                      <div className="modal-body">
                        <div className="container">
                          <div className="mb-4">
                            <p>
                              <strong>Liquidità Disponibile:</strong> {modalItem.liquidity_amount}
                            </p>
                            <p>
                              <strong>Liquidità Prelevabile:</strong> {modalItem.withdrawable_liquidity_amount}
                            </p>
                            <p>
                              <strong>Liquidità Primario:</strong> {modalItem.primary_bonus_liquidity_amount}
                            </p>
                            <p>
                              <strong>Liquidità Secondario:</strong> {modalItem.secondary_bonus_liquidity}
                            </p>
                          </div>

                          <div className="mt-4 mb-3">
                            <h5 className="text-center mt-5">Aggiungi Liquidità</h5>
                            <label htmlFor="exampleFormControlInput1" className="form-label">
                              Importo Liquidità
                            </label>
                            <input
                              type="number"
                              step=".01"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Inserisci l'importo della liquidità"
                              onChange={(e) => setLqiAmt(e.target.value)}
                              name="liquidity_amount"
                            />
                            {validationError.lqiAmt && <span className="validationErr mt-1">Errore nell'importo della liquidità</span>}
                            <div className="form-check mt-3 p-0">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="liquidityTransferCheckbox"
                                style={{ display: "none" }}
                                onChange={(e) => setIsTransfer(e.target.checked)}
                              />
                              <label className="form-check-label" htmlFor="liquidityTransferCheckbox">
                                È un bonifico bancario?
                              </label>
                            </div>
                          </div>

                          <div className="modal-footer mt-20">
                            {!saving ? (
                              <button type="submit" className="btn btn-primary">
                                Aggiungi Liquidità
                              </button>
                            ) : (
                              <button type="submit" className="btn btn-primary" disabled>
                                Attendere prego <i className="fa fa-spinner fa-spin"></i>
                              </button>
                            )}

                            <button type="button" className="btn btn-secondary" onClick={() => setShowModal(false)}>
                              Chiudi
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;
