import React, { useEffect, useRef } from 'react';
import config from '../coreFIles/config';

const PLACEHOLDER_IMAGE = "https://placehold.co/70x70?text=No+Image";

const GlobalSearchDropdown = ({ results, onSelect, isLoading, onClose }) => {
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  if (!results.length && !isLoading) return null;

  const renderUserImage = (user) => {
    if (user.signup_by === "GMAIL" || user.signup_by === "FACEBOOK" || user.signup_by === "APPLE") {
      if (parseInt(user.profile_change_stt) === 1) {
        if (user.profile_pic === 0 || user.profile_pic === null || user.profile_pic === "") {
          return "./images/avatar-2.png";
        } else {
          return `${config.imageUrl}${user.profile_pic}?optimize=true`;
        }
      } else {
        if (user.profile_pic === 0 || user.profile_pic === null || user.profile_pic === "") {
          return "./images/avatar-2.png";
        } else {
          return user.profile_pic;
        }
      }
    } else {
      if (user.profile_pic === 0 || user.profile_pic === null || user.profile_pic === "") {
        return "./images/avatar-2.png";
      } else {
        return `${config.imageUrl}${user.profile_pic}?optimize=true`;
      }
    }
  };

  return (
    <div className="global-search-dropdown" ref={dropdownRef}>
      {isLoading ? (
        <div className="search-loading">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <div className="search-results">
          {results.map((result) => {
            const isUser = result.type === 'user';
            
            return (
              <div 
                key={`${result.type}-${result.id}`} 
                className="search-result-item"
                onClick={() => onSelect(result)}
              >
                <div className="result-image">
                  {isUser ? (
                    <img 
                      src={renderUserImage(result)}
                      alt={result.name}
                      className="rounded-circle"
                    />
                  ) : (
                    <img 
                      src={result.image ? `${config.imageUrl}${result.image}?optimize=true` : PLACEHOLDER_IMAGE}
                      alt={result.name}
                    />
                  )}
                </div>
                <div className="result-info">
                  <div className="result-title">
                    {result.name}
                    <span className="result-type">{result.type}</span>
                  </div>
                  <div className="result-subtitle">
                    {isUser ? result.email : result.category?.category_name}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GlobalSearchDropdown; 