import React, { useEffect, useState, useCallback } from "react";
import BigNumber from "bignumber.js";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import toast, { Toaster } from "react-hot-toast";
import config from "../coreFIles/config";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReceiptDocument } from "../utils/ReceiptTemplate";

import {
  getCategoryListAction,
  addItemAction,
  getexpertlistAction,
  getUsdcPrice,
  getWarehouseListAction,
  getSupplierListAction,
  getItemDetailAction,
  editItemAction,
} from "../Action/action";
import moment from "moment";
// let bigInt = require("big-integer");

// Aggiungi questa importazione all'inizio del file
import WarehouseLabels from './WarehouseLabels';

const ItemAdd = () => {
  const id = window.location.href.split("/").pop();
  const isEditMode = id && id !== "itemAdd";

  // Stato per gestire il tab attivo
  const [activeTab, setActiveTab] = useState("general");

  const [form, setForm] = useState({
    category_id: "", // set default blank
    expert_id: "",
    name: "",
    lable: "",
    lable_en: "",
    item_put_on: "1",
    image: "",
    previewImage: null,
    chart: "",
    chartPreview: null,
    gallery: [],
    description: "",
    description_en: "",
    share_quantity: "",
    paymentToken: config.PaymentToken,
    priceInToken: 0,
    tokenName: "",
    tokenSymbol: "",
    tokenDecimal: "",
    last_month_price: "",
    last_month_per: "",
    share_price: "",
    tag: "0",
    tagCustom: "",
    tagCustom_en: "",
    sell_end_date: "",
    last_year_perf: "",
    market_value: "",
    value: [],
    user_email: "",
    user_shares: 0,
    last_performance_value: 0,
    last_performance_period: "one_year",
    pdf: "",
    pdfPreview: "",
    pdfTitle: "",
    market_price: 0,
    purchase_price: "",
    purchase_source: "",
    warehouse_id: "",
    supplier_id: "",
    delivered: false,
    invoice: "",
    invoicePreview: "",
    contabile: "",
    contabilePreview: "",
    paid: false,
    paid_amount: 0,
    warehouse_image: "",
    delivery_document: "",
    warehouse_details: [],
    draft: true,
    order: 0,
    galleryDeleted: [],
    thumbnails: [],
    invoice_number: "",
    invoice_date: "",
    bank: "",
    payment_date: "",
    transaction_id: "",
    stripe_transaction_id: "",
    invoice_registered: false,
    automatic_payment: false,
    payments: [],
    pre_order_target: 1,
  });

  const [categoryList, setCategoryList] = useState([]);
  const [validatioError, setvalidatioError] = useState({
    category_nameError: "",
    expert_nameError: "",
    nameError: "",
    lableError: "",
    imageError: "",
    descriptionError: "",
    share_quantityError: "",
    share_priceError: "",
    tagError: "",
    sell_end_dateError: "",
    warehouse_idError: "",
    delivery_documentError: "",
    contabileError: "",
    paid_amountError: "",
    supplier_idError: "",
    invoice_numberError: "",
    invoice_dateError: "",
    bankError: "",
    payment_dateError: "",
    transaction_idError: "",
    stripe_transaction_idError: "",
    warehouse_detailsError: "",
  });
  const [saving, setsaving] = useState(false);
  const [expertlist, setexpertlist] = useState([]);
  const [maticToEur, setMaticPrice] = useState(0);
  const [usdInEur, setUsdInEur] = useState(1);
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [disableAddVideoButton, setDisableAddVideoButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getMATICTOEUR = useCallback(async () => {
    const response = await axios.get(`https://api.binance.com/api/v3/ticker/price?symbol=MATICEUR`);
    let maticPrice = parseFloat(response.data.price).toFixed(6);
    setMaticPrice(maticPrice);
  }, []);

  const loadUsdcPrice = useCallback(async () => {
    let usdcPrice = await getUsdcPrice();
    setUsdInEur(usdcPrice.data.value);
  }, []);

  const getcategory = useCallback(async () => {
    let res = await getCategoryListAction();
    if (res.success) {
      setCategoryList(res.data);
    }
  }, []);

  const getexpert = useCallback(async () => {
    let res = await getexpertlistAction();
    if (res.success) {
      setexpertlist(res.data);
    }
  }, []);

  const onPdfChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let pdf = event.target.files[0];
      setForm((old) => {
        return { ...old, pdf: pdf, pdfPreview: "" };
      });
    }
  };

  const loadWarehouseData = useCallback(async () => {
    let res = await getWarehouseListAction();
    let resSupplier = await getSupplierListAction();
    console.log(resSupplier.data);
    console.log(res.data);
    if (res.data.success && resSupplier.success) {
      setWarehouses(res.data.data);
      setSuppliers(resSupplier.data);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getcategory();
        await getexpert();
        await getMATICTOEUR();
        await loadUsdcPrice();
        await loadWarehouseData();

        if (isEditMode) {
          let res = await getItemDetailAction({ item_id: id });
          if (res.success) {
            const data = res.data;

            const gallery =
              data.gallery?.map((img) => ({
                id: img.id,
                image: null,
                previewImage: img.file_type === "video" ? config.imageUrl + img.thumbnail : config.imageUrl + img.image_path,
                order: img.order,
                file_type: img.file_type,
                alreadyExit: true,
                videoPath: img.file_type === "video" ? config.videoUrl + img.image_path : null,
              })) || [];

            setForm({
              category_id: data.category_id || "",
              expert_id: data.expert_id || "",
              name: data.name || "",
              lable: data.lable || "",
              lable_en: data.lable_en || "",
              item_put_on: data.item_put_on || "1",
              image: "",
              previewImage: data.image ? config.imageUrl + data.image : null,
              gallery: gallery,
              description: data.description || "",
              description_en: data.description_en || "",
              share_quantity: data.share_quantity || "",
              paymentToken: data.paymentToken || config.PaymentToken,
              share_price: data.share_price || "",
              tag: data.tag || "0",
              tagCustom: data.tagCustom || "",
              tagCustom_en: data.tagCustom_en || "",
              sell_end_date: data.sell_end_date ? moment(data.sell_end_date).format("YYYY-MM-DD") : "",
              market_value: data.market_value || "",
              user_email: data.user_email || "",
              user_shares: data.user_shares || 0,
              last_performance_value: data.last_performance_value || 0,
              last_performance_period: data.last_performance_period || "one_year",
              pdf: "",
              pdfPreview: data.pdfPreview?.file ? config.apiUrl + "/itemPdf/" + data.pdfPreview.file : null,
              pdfTitle: data.pdfPreview?.title,
              market_price: data.market_price || 0,
              warehouse_id: data.warehouse_id || "",
              supplier_id: data.supplier_id || "",
              delivered: Boolean(data.delivered),
              invoice: data.invoice ? config.apiUrl + "/itemPdf/" + data.invoice : null,
              contabile: data.contabile ? config.apiUrl + "/itemPdf/" + data.contabile : null,
              invoicePreview: data.invoice_path || "",
              contabilePreview: data.contabile_path || "",
              paid: Boolean(data.paid),
              paid_amount: data.paid_amount || 0,
              warehouse_image: data.warehouse_image ? config.imageUrl + data.warehouse_image : null,
              delivery_document: data.delivery_document ? config.apiUrl + "/itemPdf/" + data.delivery_document : null,
              warehouse_details: data.warehouse_details ? JSON.parse(data.warehouse_details) : [],
              draft: Boolean(data.draft),
              order: data.order || 0,
              galleryDeleted: [],
              thumbnails: [],
              invoice_number: data.invoice_number || "",
              invoice_date: data.invoice_date ? moment(data.invoice_date).format("YYYY-MM-DD") : "",
              bank: data.bank || "",
              payment_date: data.payment_date ? moment(data.payment_date).format("YYYY-MM-DD") : "",
              transaction_id: data.transaction_id || "",
              stripe_transaction_id: data.stripe_transaction_id || "",
              invoice_registered: Boolean(data.invoice_number),
              automatic_payment: Boolean(data.automatic_payment),
              payments: data.payments || [],
              pre_order_target: data.pre_order_target || 1,
              chart: "",
              chartPreview: data.chart ? config.imageUrl + data.chart : null,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [isEditMode, id]);


  const inputHandler = async (e) => {
    const { name, value } = e.target;

    if (name === "supplier_id") {
      // Reset dei campi fattura quando cambia il fornitore
      setForm((prev) => ({
        ...prev,
        [name]: value,
        invoice_number: "",
        invoice_date: "",
      }));
    } else if (name === "share_quantity") {
      setForm({ ...form, [name]: value, market_value: value * form.share_price });
    } else if (name === "share_price") {
      setForm({ ...form, [name]: value, market_value: form.share_quantity * value });
    } else {
      setForm({ ...form, [name]: value });
    }
  };

  const inputHandler1 = (e) => {
    const { name, value } = e.target;
    setForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      setForm((old) => {
        return { ...old, image: img, previewImage: URL.createObjectURL(img) };
      });
    }
  };


  const onChartChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let chartFile = event.target.files[0];
      setForm((old) => {
        return { ...old, chart: chartFile, chartPreview: URL.createObjectURL(chartFile) };
      });
    }
  };

  const onImageGalleryChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = {
        ...gallery[index],
        image: img,
        previewImage: URL.createObjectURL(img),
        file_type: "image",
      };
      setForm((old) => {
        return { ...old, gallery: gallery };
      });
    }
  };

  const onVideoGalleryChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = {
        ...gallery[index],
        image: img,
        file_type: "video",
      };
      console.log("onVideoGalleryChange", gallery);
      setForm((old) => {
        return { ...old, gallery: gallery };
      });
    }
  };

  const onVideoThumbnailChange = (event, index) => {
    if (event.target.files && event.target.files[0]) {
      let thumbnailFile = event.target.files[0];
      let gallery = [...form.gallery];
      gallery[index] = {
        ...gallery[index],
        file_type: "video",
        previewImage: URL.createObjectURL(thumbnailFile),
      };

      // Aggiorna anche l'array thumbnails
      const newThumbnails = [...form.thumbnails];
      newThumbnails[index] = {
        file: thumbnailFile, // File della copertina
        key: gallery[index].id || index, // Usa l'ID o l'indice come chiave
      };

      setForm((old) => {
        return { ...old, gallery: gallery, thumbnails: newThumbnails };
      });
    }
  };


  function validate(draft) {
    let category_nameError = "";
    let expert_nameError = "";
    let nameError = "";
    let lableError = "";
    let imageError = "";
    let descriptionError = "";
    let share_quantityError = "";
    let share_priceError = "";
    let tagError = "";
    let sell_end_dateError = "";

    // Basic validation for both draft and publish modes
    if (form.name === "") {
      nameError = "Name is required.";
    }
    if (form.category_id === "") {
      category_nameError = "Category is required.";
    }

    // Additional validation for non-draft mode
    if (!draft) {
      if (form.id === "") {
        expert_nameError = "Expert name is required.";
      }
      if (form.lable === "") {
        lableError = "Lable is required.";
      }
      if (form.image === "" && !form.previewImage) {
        imageError = "Image is required.";
      }
      if (form.description === "") {
        descriptionError = "Description is required.";
      }
      if (form.description_en === "") {
        descriptionError = "Description (EN) is required.";
      }
      if (form.share_quantity === "") {
        share_quantityError = "Share quantity is required.";
      }
      if (form.share_price === "") {
        share_priceError = "Price is required.";
      }
      if (form.tag === "" || (form.tag === "0" && form.tagCustom === "")) {
        tagError = "Tag is required.";
      }
      if (form.sell_end_date === "") {
        sell_end_dateError = "Sell end date is required.";
      }
    }

    // Check if there are any validation errors
    if (
      nameError ||
      category_nameError ||
      (!draft &&
        (expert_nameError ||
          lableError ||
          imageError ||
          descriptionError ||
          share_quantityError ||
          share_priceError ||
          tagError ||
          sell_end_dateError))
    ) {
      setvalidatioError({
        category_nameError,
        expert_nameError,
        nameError,
        lableError,
        imageError,
        descriptionError,
        share_quantityError,
        share_priceError,
        tagError,
        sell_end_dateError,
      });
      return false;
    }

    setvalidatioError({
      category_nameError,
      nameError,
      lableError,
      imageError,
      descriptionError,
      share_quantityError,
      share_priceError,
      tagError,
      sell_end_dateError,
    });
    return true;
  }

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Processing transaction...</b></br><p>Please do not refresh or close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const imageUpload = async () => {
    let formData1 = new FormData();
    if (form.image) {
      formData1.append("file", form.image);
    } else if (form.previewImage) {
      // Convert image URL to base64
      try {
        const response = await fetch(form.previewImage);
        const blob = await response.blob();
        const base64 = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(blob);
        });

        // Convert base64 to file
        const byteString = atob(base64.split(",")[1]);
        const mimeString = base64.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const file = new File([ab], "image.jpg", { type: mimeString });
        formData1.append("file", file);
      } catch (error) {
        console.error("Error converting image:", error);
        return;
      }
    } else{
      return "";
    }

    const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;

    let resIPF = await axios.post(url, formData1, {
      headers: {
        "Content-Type": `multipart/form-data; boundary= ${formData1._boundary}`,
        pinata_api_key: "b5149f3bd2763b83d7fb",
        pinata_secret_api_key: "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021",
      },
    });
    console.log(resIPF.data);
    let ipfsImg = resIPF.data.IpfsHash;
    let data = {
      name: form.name,
      description: form.description,
      image: `ipfs://${ipfsImg}`,
    };
    let jsonData = JSON.stringify(data);
    let formData2 = new FormData();
    formData2.append("file", new Blob([jsonData], { type: "application/json" }));
    let resMetadataIPFS = await axios.post(url, formData2, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData2._boundary}`,
        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiJiYzNhMGNjZC1hMDAyLTRhZjItOTFhYS0wOTU5YzU3MmJhMTAiLCJlbWFpbCI6ImluZm9AY29sbGVjdG9hcHAuY29tIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsInBpbl9wb2xpY3kiOnsicmVnaW9ucyI6W3siZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiRlJBMSJ9LHsiZGVzaXJlZFJlcGxpY2F0aW9uQ291bnQiOjEsImlkIjoiTllDMSJ9XSwidmVyc2lvbiI6MX0sIm1mYV9lbmFibGVkIjpmYWxzZSwic3RhdHVzIjoiQUNUSVZFIn0sImF1dGhlbnRpY2F0aW9uVHlwZSI6InNjb3BlZEtleSIsInNjb3BlZEtleUtleSI6IjNkMWZmZGJmZWZiOWE5NmUyZjgzIiwic2NvcGVkS2V5U2VjcmV0IjoiNzZjNTM1MGY1ODA5N2JjYzBmZTBiMzBjM2M1NDUxMjk5ZjQ3MjkwNjUxMWRhMzcwMzk0MDgxYmI1YWYxYTYxYyIsImV4cCI6MTc2MjQ0Njk1MH0.hM66xrI5ZF9h5h6d8hd97c4Vl6DU-ACUfhuHirHoTAQ`,
        /*  "pinata_api_key": "b5149f3bd2763b83d7fb",
        "pinata_secret_api_key": "30b5d1de54b60b82c314d8a61486c6ad6a2f1bc6c2881deac21180f17df64021", */
      },
    });
    let ipfsMetadataHash = resMetadataIPFS.data.IpfsHash;
    console.log(`Metadata uploaded to IPFS with hash: ${ipfsMetadataHash}`);
    return ipfsMetadataHash;
    // await setItemUri();
  };

  const submitForm = async (e, draft = true) => {
    e.preventDefault();
    const isValid = validate(draft);
    if (!isValid) return;

    await loadUsdcPrice();

    try {
      processingAlert();
      if (!isEditMode || (isEditMode && form.draft)) {
        let ipfsImg = await imageUpload();

        const sharePriceBig = new BigNumber(form.share_price);
        const usdInEurBig = new BigNumber(usdInEur);
        const shareQuantityBig = new BigNumber(form.share_quantity);
        const share_usdc_price = sharePriceBig.multipliedBy(usdInEurBig).multipliedBy(10 ** 6);
        const marketValue = shareQuantityBig.multipliedBy(sharePriceBig);
        const minimumTarget = shareQuantityBig.multipliedBy(share_usdc_price).toFixed(0, BigNumber.ROUND_DOWN);

        form.usdc_price = usdInEur;
        form.market_value = marketValue.toString();

        console.log(parseInt(new Date(form.sell_end_date).valueOf() / 1000));
        console.log(parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000));

        let trx_data = {
          uri: `ipfs://${ipfsImg}`,
          maxShares: parseInt(form.share_quantity),
          preSaleStart: parseInt(new Date().getTime() / 1000),
          preSaleEnd: parseInt(new Date(new Date(form.sell_end_date).setMonth(new Date(form.sell_end_date).getMonth() + 2)).valueOf() / 1000),
          minimumTarget: minimumTarget.toString(),
          sharePrice: share_usdc_price.toFixed(0),
          paymentToken: form.paymentToken,
        };

        form.trx_data = JSON.stringify(trx_data);
      }
      form.draft = draft;

      // Add galleryOrder array with ordered image IDs for existing images
      form.galleryOrder = JSON.stringify(form.gallery.map((img, index) => [img.id ? img.id : null, index]).filter((img) => img[0] !== null));

      // Add newGalleryOrder array with indexes for new images
      form.newGalleryOrder = form.gallery
        .map((img, index) => ({ img, index }))
        .filter(({ img }) => !img.id)
        .map(({ index }) => index);

      if (form.warehouse_details?.length > 0) {
        form.warehouse_details = JSON.stringify(form.warehouse_details);
      }

      let atData = [];
      $(".attr").each(function (index) {
        let b = {
          attribute_id: $(this).attr("data-attr_id"),
          value: $(this).val(),
        };
        atData.push(b);
      });
      form.attribute_data = JSON.stringify(atData);

      let formData = new FormData();
      Object.keys(form).forEach((key) => {
        if (key === "gallery") {
          console.log(form[key]);
          form[key].forEach((item) => {
            if (item.image && item.image instanceof File) {
              formData.append("gallery", item.image);
            }
          });
        } else if (key === "thumbnails") {
          form[key].forEach((thumbnail) => {
            if (thumbnail.file) {
              formData.append("thumbnails", thumbnail.file);
            }
          });
        } else if (["image", "pdf", "invoice", "contabile", "warehouse_image", "delivery_document", "chart"].includes(key)) {
          if (form[key] && form[key] instanceof File) {
            formData.append(key, form[key]);
          }
        } else {
          formData.append(key, form[key]);
        }
      });
      formData.append("galleryDeleted", JSON.stringify(form.galleryDeleted));

      if (isEditMode) {
        formData.append("item_id", id);
      }

      let res = isEditMode ? await editItemAction(formData) : await addItemAction(formData);

      if (res.success) {
        toast.success(res.msg);
        closeProcessingAlert();
        setsaving(false);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}itemAdd/${res.itemId}`;
        }, 1200);
      } else {
        closeProcessingAlert();
        toast.error(res.msg);
        return false;
      }
    } catch (err) {
      console.error(err);
      closeProcessingAlert();
      if (err.code === 4001) {
        toast.error("Transaction rejected!");
      } else {
        toast.error("Transaction error: " + err.message);
      }
      return false;
    }
  };


  const moveGalleryImage = (currentIndex, direction) => {
    const newIndex = direction === "left" ? currentIndex - 1 : currentIndex + 1;
    if (newIndex < 0 || newIndex >= form.gallery.length) return;

    const newGallery = [...form.gallery];
    const temp = { ...newGallery[currentIndex] };
    newGallery[currentIndex] = { ...newGallery[newIndex] };
    newGallery[newIndex] = temp;

    setForm((prevForm) => ({
      ...prevForm,
      gallery: newGallery,
    }));
  };

  // Aggiungi questa funzione per controllare se esiste un video nella galleria
  const checkForVideoInGallery = () => {
    return form.gallery.some((item) => item.file_type === "video");
  };

  // All'interno del tuo componente, imposta disableAddVideoButton
  useEffect(() => {
    setDisableAddVideoButton(checkForVideoInGallery());
  }, [form.gallery]); // Ricalcola ogni volta che la galleria cambia


  return (
    <>
      <div className="wrapper">
        <Header />
        <Toaster />
        <Sidebar />
        <div className="content-wrapper">
          <div className="container-full">
            {isLoading ? (
              <div className="text-center p-5">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <p className="mt-2">Caricamento in corso...</p>
              </div>
            ) : (
              <section className="content">
                <div className="row">
                  <div className="col-xl-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">{isEditMode ? "Modifica Item" : "Aggiungi Nuovo Item"}</h4>
                        <a className="text-primary" href={`${config.baseUrl}itemList`}>
                          <i className="fa fa-arrow-left"></i> Back
                        </a>
                      </div>

                      {/* Tabs Navigation */}
                      <div className="row mt-3">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <a 
                                className={`nav-link ${activeTab === "general" ? "active" : ""}`} 
                                onClick={(e) => {
                                  e.preventDefault();
                                  setActiveTab("general");
                                }}
                                href="#"
                                role="tab"
                              >
                                <i className="fa fa-info-circle mr-1"></i> Informazioni Generali
                              </a>
                            </li>
                            {isEditMode && <li className="nav-item">
                              <a 
                                className={`nav-link ${activeTab === "warehouse" ? "active" : ""}`} 
                                onClick={(e) => {
                                  e.preventDefault();
                                  setActiveTab("warehouse");
                                }}
                                href="#"
                                role="tab"
                              >
                                <i className="fa fa-warehouse mr-1"></i> Gestione Magazzino
                              </a>
                            </li>}
                          </ul>
                        </div>
                        <div className="col-md-2" />
                      </div>

                      {/* Tab Content */}
                      <div className="tab-content">
                        {/* Tab Informazioni Generali */}
                        <div className={`tab-pane ${activeTab === "general" ? "active" : ""}`} id="general" role="tabpanel">
                      <div className="row mt-20 mb-50">
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Category</label>
                              <div className="col-md-12">
                                <select className="form-control" name="category_id" value={form.category_id} onChange={inputHandler}>
                                  <option value="">-Select Category-</option>
                                  {categoryList.map((item) => (
                                    <option value={item.id}>{item.category_name}</option>
                                  ))}
                                </select>
                              </div>
                              <span className="validationErr">{validatioError.category_nameError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Expert List</label>
                              <div className="col-md-12">
                                <select className="form-control" name="expert_id" value={form.expert_id} onChange={inputHandler1}>
                                  <option value="">-Select Expert List-</option>
                                  {expertlist.map((item) => (
                                    <option value={item.id}>{item.expert_name}</option>
                                  ))}
                                </select>
                              </div>
                              <span className="validationErr">{validatioError.expert_nameError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Name</label>
                              <div className="col-md-12">
                                <input className="form-control" type="text" autoComplete="off" name="name" value={form.name} onChange={inputHandler} placeholder="Item Name" />
                              </div>
                              <span className="validationErr">{validatioError.nameError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>

                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Lable</label>
                              <div className="col-md-12">
                                <input className="form-control" type="text" autoComplete="off" name="lable" value={form.lable} onChange={inputHandler} placeholder="Lable" />
                              </div>
                              <span className="validationErr">{validatioError.lableError}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Lable (EN)</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  name="lable_en"
                                  value={form.lable_en}
                                  onChange={inputHandler}
                                  placeholder="Lable (EN)"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                          <div className="col-md-4" />

                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Tag</label>
                              <div className="col-md-12">
                                <select className="form-control" name="tag" value={form.tag} onChange={inputHandler}>
                                  <option value={0}>Custom Tag</option>
                                  <option value={1}>Best Seller</option>
                                  <option value={2} selected>
                                    New Item
                                  </option>
                                  <option value={3}>Holding Period 1 year</option>
                                  <option value={4}>Holding Period 2 years</option>
                                  <option value={5}>Holding Period 3 years</option>
                                  <option value={6}>Holding Period 4 years</option>
                                  <option value={7}>Holding Period 5 years</option>
                                </select>
                              </div>
                            </div>
                            {(form.tag !== 0 || form.tag !== "0") && <span className="validationErr">{validatioError.tagError}</span>}
                          </div>
                          <div className="col-md-2" />
                        </div>
                        {(form.tag === 0 || form.tag === "0") && (
                          <>
                            <div className="row">
                              <div className="col-md-6" />
                              <div className="col-md-4">
                                <div className="form-group row mb-1">
                                  <label className="col-form-label col-md-12">Custom Tag</label>
                                  <div className="col-md-12">
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      name="tagCustom"
                                      value={form.tagCustom}
                                      onChange={inputHandler}
                                      placeholder="Custom Tag"
                                    />
                                  </div>
                                </div>
                                <span className="validationErr">{validatioError.tagError}</span>
                              </div>
                              <div className="col-md-2" />
                            </div>
                            <div className="row">
                              <div className="col-md-6" />
                              <div className="col-md-4">
                                <div className="form-group row mb-1">
                                  <label className="col-form-label col-md-12">Custom Tag (EN)</label>
                                  <div className="col-md-12">
                                    <input
                                      className="form-control"
                                      type="text"
                                      autoComplete="off"
                                      name="tagCustom_en"
                                      value={form.tagCustom_en}
                                      onChange={inputHandler}
                                      placeholder="Custom Tag (EN)"
                                    />
                                  </div>
                                </div>
                                <span className="validationErr">{validatioError.tagError}</span>
                              </div>
                              <div className="col-md-2" />
                            </div>
                          </>
                        )}
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">ItemPutOn</label>
                              <div className="col-md-12">
                                <select className="form-control" name="item_put_on" value={form.item_put_on} onChange={inputHandler}>
                                  <option value={1}>Pre-order</option>
                                  {/* <option value={2}>Marketplace</option> */}
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Sell End Date</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="date"
                                  autoComplete="off"
                                  name="sell_end_date"
                                  value={form.sell_end_date}
                                  onChange={inputHandler}
                                  placeholder="Sell end date"
                                />
                              </div>
                              <span className="validationErr">{validatioError.sell_end_dateError}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Image</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="image" onChange={onImageChange} />
                                <img alt="" src={form.previewImage ?? "https://placehold.co/70x70?text=No+Image"} width="100px" height="100px"></img>
                              </div>
                              <span className="validationErr">{validatioError.imageError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>

                        

                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group">
                              <label className="col-form-label">Galleria Immagini</label>
                              <div
                                className="gallery-container"
                                style={{
                                  maxWidth: "800px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: "10px",
                                }}
                              >
                                {form.gallery.map((val, key) => {
                                  if (val.file_type === "video") {
                                    return (
                                      <div
                                        className="border rounded p-2"
                                        key={key}
                                        style={{
                                          width: "250px",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        {val.alreadyExit ? (
                                          <video
                                            controls
                                            style={{
                                              width: "100%",
                                              height: "150px",
                                              objectFit: "cover",
                                              marginBottom: "10px",
                                            }}
                                          >
                                            <source src={form.gallery[key].videoPath ?? ""} type="video/mp4" />
                                            Your browser does not support the video tag.
                                          </video>
                                        ) : (
                                          <img
                                            alt=""
                                            src={form.gallery[key].previewImage ?? ""}
                                            style={{
                                              width: "100%",
                                              height: "150px",
                                              objectFit: "cover",
                                              marginBottom: "10px",
                                            }}
                                          />
                                        )}
                                        {val.alreadyExit != true && (
                                          <>
                                            <button onClick={() => document.getElementById(`video-gallery-${key}`).click()}>Scegli video</button>
                                            <input
                                              id={`video-gallery-${key}`}
                                              className="form-control form-control-sm mb-2"
                                              type="file"
                                              accept="video/*"
                                              style={{ display: "none" }}
                                              onChange={(e) => onVideoGalleryChange(e, key)}
                                              key={`gallery-input-${key}-${form.gallery[key].image?.name || "empty"}`}
                                            />

                                            <button onClick={() => document.getElementById(`video-thumbnail-${key}`).click()}>Scegli copertina video</button>
                                            <input
                                              id={`video-thumbnail-${key}`}
                                              className="form-control form-control-sm mb-2"
                                              type="file"
                                              accept="image/*"
                                              onChange={(e) => onVideoThumbnailChange(e, key)}
                                              style={{ display: "none" }}
                                              key={`thumbnail-input-${key}-${form.gallery[key].image?.name || "empty"}`}
                                            />
                                          </>
                                        )}
                                        <div className="btn-group mt-2">
                                          <button type="button" className="btn btn-sm btn-primary" disabled={key === 0} onClick={() => moveGalleryImage(key, "left")}>
                                            <i className="fa fa-arrow-left"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            disabled={key === form.gallery.length - 1}
                                            onClick={() => moveGalleryImage(key, "right")}
                                          >
                                            <i className="fa fa-arrow-right"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => {
                                              let gallery = form.gallery;
                                              const deletedImage = form.gallery[key];

                                              if (deletedImage.id) {
                                                setForm((prev) => ({
                                                  ...prev,
                                                  galleryDeleted: [...prev.galleryDeleted, deletedImage.id],
                                                }));
                                              }

                                              gallery = gallery.filter((el) => el !== form.gallery[key]);
                                              setForm((prev) => ({
                                                ...prev,
                                                gallery: gallery,
                                              }));
                                            }}
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="border rounded p-2"
                                        key={key}
                                        style={{
                                          width: "250px",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src={form.gallery[key].previewImage ?? ""}
                                          style={{
                                            width: "100%",
                                            height: "150px",
                                            objectFit: "cover",
                                            marginBottom: "10px",
                                          }}
                                        />
                                        {val.alreadyExit != true && (
                                          <input
                                            className="form-control form-control-sm mb-2"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => onImageGalleryChange(e, key)}
                                            key={`gallery-input-${key}-${form.gallery[key].image?.name || "empty"}`}
                                          />
                                        )}

                                        <div className="btn-group">
                                          <button type="button" className="btn btn-sm btn-primary" disabled={key === 0} onClick={() => moveGalleryImage(key, "left")}>
                                            <i className="fa fa-arrow-left"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-primary"
                                            disabled={key === form.gallery.length - 1}
                                            onClick={() => moveGalleryImage(key, "right")}
                                          >
                                            <i className="fa fa-arrow-right"></i>
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger"
                                            onClick={() => {
                                              let gallery = form.gallery;
                                              const deletedImage = form.gallery[key];

                                              if (deletedImage.id) {
                                                setForm((prev) => ({
                                                  ...prev,
                                                  galleryDeleted: [...prev.galleryDeleted, deletedImage.id],
                                                }));
                                              }

                                              gallery = gallery.filter((el) => el !== form.gallery[key]);
                                              setForm((prev) => ({
                                                ...prev,
                                                gallery: gallery,
                                              }));
                                            }}
                                          >
                                            <i className="fa fa-trash"></i>
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </div>
                              <button
                                type="button"
                                className="btn btn-primary mt-3"
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    gallery: [
                                      ...form.gallery,
                                      {
                                        previewImage: "./images/noimage.png",
                                        image: "",
                                      },
                                    ],
                                    thumbnails: [...form.thumbnails],
                                  })
                                }
                              >
                                <i className="fa fa-plus"></i> Aggiungi immagine
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary mt-3 ms-3"
                                disabled={disableAddVideoButton}
                                onClick={() =>
                                  setForm({
                                    ...form,
                                    gallery: [
                                      ...form.gallery,
                                      {
                                        previewImage: "./images/noimage.png",
                                        image: "",
                                        file_type: "video",
                                      },
                                    ],
                                    thumbnails: [
                                      ...form.thumbnails,
                                      { file: null, key: null }, // Aggiungi un nuovo oggetto per la copertina
                                    ],
                                  })
                                }
                              >
                                <i className="fa fa-plus"></i> Aggiungi video
                              </button>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Chart</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="chart" onChange={onChartChange} />
                                <img alt="" src={form.chartPreview ?? "https://placehold.co/70x70?text=No+Image"} width="100px" height="100px"></img>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Fact sheet (pdf)</label>
                              <div className="col-md-12">
                                <input className="form-control mb-2" type="file" name="pdf" onChange={onPdfChange} />
                                {form.pdfPreview && (
                                  <a className=" btn btn-primary" rel="noreferrer" target="_blank" href={form.pdfPreview}>
                                    Go to pdf
                                  </a>
                                )}
                                <label className="col-form-label col-md-12">Fact sheet title</label>
                                <input
                                  className="form-control mb-2"
                                  type="text"
                                  name="pdfTitle"
                                  value={form.pdfTitle}
                                  onChange={(e) => setForm({ ...form, pdfTitle: e.target.value })}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Short Description</label>
                              <div className="col-md-12">
                                <textarea className="form-control" value={form.description} name="description" onChange={inputHandler} placeholder="Description"></textarea>
                              </div>
                              <span className="validationErr">{validatioError.descriptionError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>
                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-8">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Short Description (EN)</label>
                              <div className="col-md-12">
                                <textarea className="form-control" value={form.description_en} name="description_en" onChange={inputHandler} placeholder="Description"></textarea>
                              </div>
                              <span className="validationErr">{validatioError.descriptionError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>

                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Share Quantity</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  name="share_quantity"
                                  value={form.share_quantity}
                                  onChange={inputHandler}
                                  placeholder="Share Quantity"
                                  readOnly={!form.draft}
                                />
                              </div>
                              <span className="validationErr">{validatioError.share_quantityError}</span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Share Price {`( Matic Price : ${parseFloat(form.share_price / maticToEur).toFixed(6)} )`}</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  name="share_price"
                                  value={form.share_price}
                                  onChange={inputHandler}
                                  placeholder="Share Price €"
                                  readOnly={!form.draft}
                                />
                              </div>
                              <span className="validationErr">{validatioError.share_priceError}</span>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>

                        <div className="row">
                          <div className="col-md-2" />
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Last Perf. Value %</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="number"
                                  autoComplete="off"
                                  name="last_performance_value"
                                  value={form.last_performance_value}
                                  onChange={inputHandler}
                                  placeholder="Last perf."
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Last Perf. period</label>
                              <div className="col-md-12">
                                <select className="form-control" name="last_performance_period" value={form.last_performance_period} onChange={inputHandler}>
                                  <option value="">Select Period</option>
                                  <option value="one_month">1 Month</option>
                                  <option value="two_month">2 Months</option>
                                  <option value="three_month">3 Months</option>
                                  <option value="six_month">6 Months</option>
                                  <option value="one_year">1 Year</option>
                                  <option value="two_year">2 Years</option>
                                  <option value="three_year">3 Years</option>
                                  <option value="four_year">4 Years</option>
                                  <option value="five_year">5 Years</option>
                                  <option value="six_year">6 Years</option>
                                  <option value="seven_year">7 Years</option>
                                  <option value="eight_year">8 Years</option>
                                  <option value="nine_year">9 Years</option>
                                  <option value="ten_year">10 Years</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                          <div className="col-md-2" />

                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Market Value</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="text"
                                  autoComplete="off"
                                  name="market_value"
                                  value={form.market_value}
                                  onChange={inputHandler}
                                  placeholder="Market value"
                                  readOnly={!form.draft}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="form-group row mb-1">
                              <label className="col-form-label col-md-12">Real Market Price</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="number"
                                  autoComplete="off"
                                  name="market_price"
                                  value={form.market_price}
                                  onChange={inputHandler}
                                  placeholder="Market price"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                        </div>


                        <div className="row">
                          <div className="col-md-2" />
                              <div className="col-md-8">
                            <div className="form-group row mb-1">
                                  <label className="col-form-label col-md-12">Ordine di visualizzazione</label>
                              <div className="col-md-12">
                                <input
                                  className="form-control"
                                  type="number"
                                  autoComplete="off"
                                      name="order"
                                      value={form.order}
                                  onChange={inputHandler}
                                      placeholder="Ordine di visualizzazione"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2" />
                            </div>
                          </div>
                        </div>

                        {/* Tab Gestione Magazzino */}
                        <div className={`tab-pane ${activeTab === "warehouse" ? "active" : ""}`} id="warehouse" role="tabpanel">
                          <div className="row mt-20 mb-50">
                          <div className="row">
                            <div className="col-md-2" />
                            <div className="col-md-8">
                                <h4 className="box-title mb-4">Gestione Magazzino</h4>
                            </div>
                            <div className="col-md-2" />
                          </div>
                            
                            {/* ... resto del contenuto del tab warehouse ... */}

                        <div className="row mt-4 mb-4">
                          <div className="col-md-2" />
                          <div className="col-md-8">

                            <WarehouseLabels 
                              warehouseDetails={form.warehouse_details || []}
                              setForm={setForm}
                              suppliers={suppliers}
                              warehouses={warehouses}
                              itemId={id !== "itemAdd" ? id : null}
                              pre_order_target={form.pre_order_target}
                            />
                          </div>
                          <div className="col-md-2" />
                        </div>
                              </div>
                            </div>
                        </div>

                      {/* Pulsanti di azione */}
                      {activeTab !== "warehouse" && <div className="row mb-4">
                        <div className="col-md-2" />
                        <div className="col-md-8">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary me-2"
                            onClick={(e) => {
                              e.preventDefault();
                              Swal.fire({
                                title: "Conferma",
                                text: isEditMode ? "Sei sicuro di voler pubblicare questo bene?" : "Sei sicuro di voler pubblicare questo bene?",
                                icon: "question",
                                showCancelButton: true,
                                confirmButtonText: isEditMode ? "Sì, pubblica" : "Sì, salva",
                                cancelButtonText: "Annulla",
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  submitForm(e, false);
                                }
                              });
                            }}
                          >
                            {form.draft ? "Pubblica in app" : "Aggiorna"}
                          </button>
                          {form.draft && (
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                submitForm(e, true);
                              }}
                            >
                              Salva come bozza
                            </button>
                          )}
                        </div>
                        </div>
                        <div className="col-md-2" />
                      </div>}
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ItemAdd;