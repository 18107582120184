import React, { useEffect, useState, useMemo } from "react";
// import config from '../coreFIles/config'
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import config from "../coreFIles/config";
import {  getItemListAction, updateItemOnboardingAction, refundItemAction, liquidateItem, getFavouriteUsersForItemsAction } from "../Action/action";
import Swal from "sweetalert2";
import toast, { Toaster } from "react-hot-toast";
// import moment from "moment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

const PLACEHOLDER_IMAGE = "https://placehold.co/70x70?text=No+Image";

const Items = () => {
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState({});
  const [saving, setSaving] = useState(false);
  const [sellPrice, setSellPrice] = useState(0);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      if (!data.length) {
        setIsLoading(true);
      } else {
        setIsRefetching(true);
      }

      try {
        let filters = JSON.stringify(columnFilters ?? []);
        console.log('filtersToUse:', filters);

        const response = await getItemListAction(
          pagination.pageIndex,
          pagination.pageSize,
          filters,
        );
        console.log('response:', response);


        if (response.success) {
          setData(response.data.items);
          setRowCount(response.data.totalItems);
        }
      } catch (error) {
        setIsError(true);
        console.error(error);
      }
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    };

    fetchData();
  }, [
    columnFilters,
    pagination.pageIndex,
    pagination.pageSize,
  ]);

  const getItems = async (pageIndex, pageSize) => {
    setIsLoading(true);
    try {
      const page = pagination.pageIndex; // Converte da 0-based a 1-based
      const limit = pagination.pageSize;

      let res = await getItemListAction(page, limit);
      if (res.success) {
        //setItemList(res.data.items);
        setRowCount(res.data.totalItems);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const processingAlert = async () => {
    let timerInterval;
    Swal.fire({
      title: '<span style="color:red;">Please Wait</span>',
      html: "<b>Transaction is being process...</b></br><p>Please do not close the window</p>",
      timer: 1000000,
      timerProgressBar: true,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
      willClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Transaction done!");
      }
    });
  };

  const closeProcessingAlert = async () => {
    Swal.close();
  };

  const sellItem = async (e) => {
    e.preventDefault();
    try {
      console.log(item.id);
      setError(false);
      setSaving(true);

      await liquidateItem(item.id, { amount: sellPrice });
      Swal.fire({
        title: "Item sold!",
        text: "Item sold successfully!",
        icon: "success",
      });
    } catch (error) {
      setError(true);
      Swal.fire({
        title: "Error!",
        text: "Item sold failed!",
        icon: "error",
      });
    }
    setSaving(false);
    setShowModal(false);
    setItem({});
    setSellPrice(0);

    getItems();
  };

  const onboardItem = async (item) => {
    processingAlert();
    try {
      let res = await updateItemOnboardingAction({ item_id: item.id });
      console.log(res);
      // Simulate a delay of 10 seconds
      await new Promise((resolve) => setTimeout(resolve, 20000));
      toast.success("Item onboarded successfully");
    } catch (err) {
      toast.error("Transaction reverted: " + err.toString().split("execution reverted:")[1]?.toString().split('"')[0]);
      return false;
    } finally {
      closeProcessingAlert();
      // Reload the page after onboarding
      getItems();
    }
  };

  const downloadCsv = async () => {
    const response = await getItemListAction();
    console.log(response);
    if (response.success) {
      const csvData = [
        ["ID", "Category", "Name", "Share Quantity", "Share Price", "Market Value", "Purchase Price", "Purchase Source","Supplier type", "Warehouse", "Market Price", "Expiration", "Creation Date", "Label","Resell Price","Total bonus liquidity used", "Total paid"],
        ...response.data.items.map((item) => [
          item.id,
          item.category.category_name,
          `"${item.name}"`,
          item.share_quantity,
          `"${item.share_price?.toString().replace(".", ",")}"`,
          `"${item.market_value?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.lots.map(lot => lot.price).reduce((acc, curr) => acc + Number(curr), 0)?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${item.lots.map(lot => lot.supplier.name).join("\n") ?? "-"}"`,
          `"${item.lots.map(lot => lot.supplier.type).join("\n") ?? "-"}"`,
          `"${item.lots.map(lot => lot.warehouse?.name ?? "N/A").join("\n") ?? "-"}"`,
          `"${item.market_price ? Math.round(item.market_price).toString() : "N/A"}"`,
          `"${item.tagCustom ?? "N/A"}"`,
          item.created_at,
          `"${item.lable ?? "N/A"}"`,
          `"${item.last_buyback_price * item.share_quantity ?? "N/A"}"`,
          `"${item.total_bonus_liquidity?.toString().replace(".", ",") ?? "N/A"}"`,
          `"${(item.market_value - item.total_bonus_liquidity)?.toString().replace(".", ",") ?? "N/A"}"`,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "items_list.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } else {
      console.error("Failed to retrieve daily transactions");
    }
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        size: 50, //small column
      },
      {
        accessorKey: "category",
        header: "Category",
        accessorFn: (row) => row.category.category_name,
        size: 100, //medium column
      },
      {
        accessorKey: "name",
        header: "Name",
      },
      {
        accessorKey: "image",
        header: "Image",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            <a target="__blank" href={row.original.image ? `${config.imageUrl}${row.original.image}?optimize=true` : PLACEHOLDER_IMAGE}>
              <img 
                src={row.original.image ? `${config.imageUrl}${row.original.image}?optimize=true` : PLACEHOLDER_IMAGE} 
                width="70px" 
                height="70px" 
                alt={row.original.name || "Item"} 
              />
            </a>
          </>
        ),
        size: 100, //medium column
      },
      {
        accessorKey: "details",
        header: "Details",
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            <b>Total Share : </b>
            {row.original.share_quantity}
            <br />
            <b>Price : </b>€ {row.original.share_price}/share
            <br />
            <b>Last Year Perf : </b>
            {row.original.last_year_perf}%<br />
            <b>Market Value : </b>€ {row.original.market_value}
            <br />
            <b>Last Month Price : </b>€ {row.original.last_month_price}
          </>
        ),
      },
      {
        accessorKey: "start_price",
        header: "Initial Price",
        enableColumnFilter: false,
        Cell: ({ row }) => `${row.original.share_price * row.original.share_quantity}€`,
        size: 50, //medium column
      },
      {
        accessorKey: "market_price",
        header: "Market Price",
        enableColumnFilter: false,
        Cell: ({ row }) => `${Math.round(row.original.market_price)}€`,
        size: 100, //medium column
      },
      {
        accessorKey: "tagCustom",
        header: "Expiration Date",
        enableColumnFilter: false,
        Cell: ({ row }) => `${row.original.tagCustom}`,
        size: 100, //medium column
      },
      {
        accessorKey: "created_at",
        header: "Creation date",
        enableColumnFilter: false,
        Cell: ({ row }) => `${row.original.created_at}`,
        size: 100, //medium column
      },
      {
        accessorKey: "supplier",
        header: "Supplier",
        enableColumnFilter: false,
        size: 250, //medium column
        Cell: ({ row }) => {
          if (!row.original.lots || row.original.lots.length === 0) return "-";
          
          return (
            <div>
              {row.original.lots.map((lot, index) => (
                <div key={index}>
                  {lot.supplier_id ? (
                    <a 
                      href={`${config.baseUrl}fornitori/${lot.supplier_id}`} 
                      className="text-primary" 
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {lot.supplier.name}
                    </a>
                  ) : "-"}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "warehouse",
        header: "Warehouse", 
        enableColumnFilter: false,
        size: 250, //medium column
        Cell: ({ row }) => {
          if (!row.original.lots || row.original.lots.length === 0) return "-";
          
          return (
            <div>
              {row.original.lots.map((lot, index) => (
                <div key={index}>
                  {lot.warehouse_id ? (
                    <a 
                      href={`${config.baseUrl}magazzini/${lot.warehouse_id}`} 
                      className="text-primary" 
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                    >
                      {lot.warehouse.name}
                    </a>
                  ) : "-"}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        accessorKey: "last_buyback_price",
        header: "Resell Price",
        enableColumnFilter: false,
        Cell: ({ row }) => row.original.last_buyback_price ? `${row.original.last_buyback_price * row.original.share_quantity}€` : '-------',
        size: 100, //medium column
      },
      {
        accessorKey: "draft",
        header: "Bozza",
        enableColumnFilter: true,
        filterFn: (row, id, filterValue) => {
          return row.original.draft === (filterValue === 'true');
        },
        filterVariant: 'select',
        filterSelectOptions: [
          { value: true, text: 'Bozza' },
          { value: false, text: 'Pubblico' }
        ],
        Cell: ({ row }) => <span className={`badge ${row.original.draft ? "bg-warning" : "bg-success"}`}>{row.original.draft ? "Bozza" : "Pubblicato"}</span>,

      },
      {
        header: "Onboard",
        size: 100, //medium column
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            {parseInt(row.original.pre_order_target) == 1 && row.original.onboarded === false && row.original.fractionalize === false ? (
              <button type="button" className="btn btn-sm btn-default" id="editbtnid" onClick={() => onboardItem(row.original)}>
                Onboard
              </button>
            ) : (
              ""
            )}
            {row.original.onboarded === true && row.original.fractionalize === false ? "Fractionalizing..." : ""}
            {row.original.onboarded === true && row.original.fractionalize === true ? (
              <a target="__blank" href={`${config.blockchainUrl}tx/${row.original.fractionalizeHash}`}>
                <i className="fa fa-check"> Onboarded & Fractionalized</i>
              </a>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        accessorKey: "sell",
        header: "Sell",
        size: 100, //medium column
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            {row.original.item_put_on === 3 && <span className="badge bg-success">Sold</span>}
            {row.original.onboarded === true && row.original.fractionalize === true && row.original.item_put_on !== 3 ? (
              <button
                type="button"
                className="btn btn-sm btn-default"
                id="editbtnid"
                onClick={() => {
                  setItem(row.original);
                  setShowModal(true);
                }}
              >
                Sell item
              </button>
            ) : (
              ""
            )}
          </>
        ),
      },
      {
        accessorKey: "action",
        header: "Action",
        size: 100, //medium column
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <>
            <a href={`${config.baseUrl}itemAdd/${row.original.id}`} className="btn btn-primary btn-sm">
              Modifica
            </a>
            <br />
            <br />
            {(!row.original.onboarded) && (
              <button 
                type="button" 
                className="btn btn-sm btn-danger" 
                id="editbtnidd" 
                onClick={() => row.original.draft ? deleteItem(row.original.id) : refundItem(row.original.id)}
              >
                {row.original.draft ? 'Elimina' : 'Rimborsa'}
              </button>
            )}
          </>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row.id,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    muiToolbarAlertBannerProps: isError
    
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
      rowSelection,
    },
    // Stili della tabella
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
  });

  useEffect(() => {
    const selectedRows = Object.keys(rowSelection)
      .filter(key => rowSelection[key])
      .map(id => parseInt(id));
    setSelectedIds(selectedRows);
  }, [rowSelection]);



  const refundItem = async (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Vuoi rimborsare questo item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Rimborsa!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await refundItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Rimborsato!", res.msg, "success");
        } else {
          Swal.fire("Fallito!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };


  const deleteItem = async (id) => {
    Swal.fire({
      title: "Sei sicuro?",
      text: "Vuoi eliminare questo item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Elimina!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await refundItemAction({ item_id: id });
        if (res.success) {
          getItems();
          // toast.success(res.msg);
          Swal.fire("Eliminato!", res.msg, "success");
        } else {
          Swal.fire("Fallito!", res.msg, "error");
          // toast.error(res.msg);
        }
      }
    });
  };


  // Utility function to calculate and format price per share
  const calculatePricePerShare = (totalPrice, quantity) => {
    if (!totalPrice || !quantity) return '0.00';
    const pricePerShare = totalPrice / quantity;
    // Floor to 2 decimal places
    return Math.floor(pricePerShare * 100) / 100;
  };

  return (
    <>
      <ThemeProvider theme={tableTheme}>
        <div className="wrapper">
          <Header />
          <Toaster />
          <Sidebar />
          <div className="content-wrapper">
            <div className="container-full">
              <section className="content">
                <div className="row">
                  <div className="col-lg-12 col-12">
                    <div className="box">
                      <div className="box-header with-border">
                        <h4 className="box-title">Items List</h4>
                        {selectedIds.length > 0 && (
                          <button 
                            type="button" 
                            className="btn btn-sm btn-primary"
                            onClick={async () => {
                              try {
                                const response = await getFavouriteUsersForItemsAction(selectedIds);
                                if (response.success) {
                                  if (response.data.items.length === 0) {
                                    Swal.fire({
                                      icon: 'info',
                                      title: 'Nessun utente',
                                      text: 'Nessun utente ha aggiunto questi item ai preferiti'
                                    });
                                    return;
                                  }

                                  // Creiamo il CSV dai dati della response
                                  const csvData = [
                                    ["Item ID", "Nome Item", "ID Utente", "Nome Utente", "Email", "Telefono"], // Headers
                                    ...response.data.items.flatMap(item => 
                                      item.users.map(user => [
                                        item.item_id,
                                        item.item_name,
                                        user.id,
                                        user.name,
                                        user.email,
                                        `${user.country_code}${user.mobile_no}`
                                      ])
                                    )
                                  ];

                                  const csvString = csvData.map(row => row.join(",")).join("\n");
                                  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
                                  const link = document.createElement("a");
                                  const url = URL.createObjectURL(blob);
                                  link.setAttribute("href", url);
                                  link.setAttribute("download", "utenti_preferiti.csv");
                                  link.style.visibility = "hidden";
                                  document.body.appendChild(link);
                                  link.click();
                                  document.body.removeChild(link);
                                }
                              } catch (error) {
                                Swal.fire({
                                  icon: 'error',
                                  title: 'Errore',
                                  text: 'Impossibile recuperare gli utenti'
                                });
                              }
                            }}
                          >
                            Esporta utenti preferiti ({selectedIds.length})
                          </button>
                        )}
                        <button type="button" className="btn btn-sm btn-primary add_btn" onClick={downloadCsv}>
                          Download CSV
                        </button>
                        <a href={`${config.baseUrl}itemAdd`} className="btn btn-sm btn-primary add_btn">
                          Add New
                        </a>
                      </div>
                      <div className="box-body">
                        <MaterialReactTable table={table} />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="modal " id="liquidityModal" aria-labelledby="liquidityModalLabel" style={{ display: showModal ? "block" : "none" }}>
                <div className="modal-dialog">
                  <div className="modal-content">
                    {/* await items is not empty */}
                    {Object.keys(item).length > 0 ? (
                      <>
                        <div className="modal-header" data-bs-theme="dark">
                          <h5 className="modal-title" id="liquidityModalLabel">
                            Sell item
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setShowModal(false);
                              setError(false);
                            }}
                          />
                        </div>
                        {error ? (
                          <div className="modal-body">
                            <div className="container">
                              <div className="mb-3">
                                <p className="text-center">Something went wrong. Please try again later</p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <form onSubmit={(e) => sellItem(e)}>
                            <div className="modal-body">
                              <div className="container">
                                <div className="mb-4 d-flex align-items-center">
                                  <img 
                                    src={item.image ? `${config.imageUrl}${item.image}` : PLACEHOLDER_IMAGE} 
                                    width="70px" 
                                    height="70px" 
                                    alt={item.name || "Item"}
                                  />
                                  <div className="p-3">
                                    <p>
                                      <strong>Item:</strong> {item.name}
                                    </p>
                                    <p>
                                      <strong>Category:</strong> {item.category.category_name}
                                    </p>
                                    <p>
                                      <strong>Shares:</strong> {item.share_quantity}
                                    </p>
                                    <p>
                                      <strong>Price per share:</strong> €{calculatePricePerShare(sellPrice, item.share_quantity)}
                                    </p>
                                  </div>
                                </div>

                                <div className="mt-4 mb-3">
                                  <label htmlFor="exampleFormControlInput1" className="form-label">
                                    Sell price (€)
                                  </label>
                                  <input
                                    type="number"
                                    step=".01"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter sell price"
                                    onChange={(e) => setSellPrice(e.target.value)}
                                    name="sell_price"
                                  />
                                  {/*                                   {validatioError.lqiAmt && <span className="validationErr mt-1">Liquidity amount error</span>}
                                   */}
                                </div>

                                <div className="modal-footer mt-20">
                                  {!saving ? (
                                    <button type="submit" className="btn btn-primary">
                                      Set sell price
                                    </button>
                                  ) : (
                                    <button type="submit" className="btn btn-primary" disabled>
                                      Please wait <i className="fa fa-spinner fa-spin"></i>
                                    </button>
                                  )}

                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowModal(false);
                                      setError(false);
                                    }}
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              {/*Sell item Modal Ends */}
            </div>
          </div>

          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
};
export default Items;
