import React, { useEffect, useState, useMemo } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import Sidebar from "../directives/sidebar";
import { deleteAlertRequestAction, getAlertRequestListAction } from "../Action/action";
import Swal from "sweetalert2";
import moment from "moment";
// New imports for Material React Table
import { MaterialReactTable, useMaterialReactTable } from "material-react-table";
import { createTheme, ThemeProvider, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { CSVLink } from "react-csv";

const Alert_Request = () => {
  
  // New state variables for Material React Table
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [csvLoading, setCsvLoading] = useState(false);

  // Table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    getAlert_Request();
  }, []);

  // Reset dei dati CSV quando cambiano i filtri
  useEffect(() => {
    // Quando cambiano i filtri, resettiamo i dati CSV per forzare la rigenerazione
    setCsvData([]);
  }, [columnFilters]);

  const getAlert_Request = async () => {
    setIsLoading(true);
    let res = await getAlertRequestListAction();
    if (res.success) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  // Funzione che restituisce i dati filtrati dalla tabella
  const getFilteredData = () => {
    // Se non ci sono filtri, restituisce tutti i dati
    if (!columnFilters.length) {
      return data;
    }
    
    // Filtra i dati in base ai filtri applicati
    return data.filter(row => {
      return columnFilters.every(filter => {
        const { id, value } = filter;
        if (!value) return true;
        
        // Per filtri di testo
        if (typeof value === 'string') {
          const cellValue = String(row[id] || '').toLowerCase();
          return cellValue.includes(value.toLowerCase());
        }
        
        return true;
      });
    });
  };

  // Funzione per preparare i dati CSV in formato corretto per l'esportazione diretta
  const prepareExportData = () => {
    if (!data || data.length === 0) return [];
    
    try {
      // Utilizzo i dati filtrati più recenti
      const filteredData = getFilteredData();
      
      // Preparo le intestazioni
      const headers = [
        "ID", 
        "Nome", 
        "Email", 
        "Telefono", 
        "Prodotto", 
        "Categoria", 
        "Data Richiesta"
      ];
      
      // Preparo le righe
      const rows = filteredData.map((alert, index) => [
        index + 1,
        alert.name || "-",
        alert.email || "-",
        alert.mobile_no ? alert.country_code + " " + alert.mobile_no : "-",
        alert.item_name || "-",
        alert.category_name || "-",
        moment(alert.request_date).format("YYYY-MM-DD")
      ]);
      
      // Costruisco il CSV finale
      return [
        headers,
        ...rows
      ];
    } catch (error) {
      console.error("Errore nella preparazione dati CSV:", error);
      return [];
    }
  };

  // Funzione per preparare i dati CSV (salvati nello stato)
  const handleCsvDownload = () => {
    if (!data || data.length === 0) return;
    
    setCsvLoading(true);
    
    try {
      // Usa la funzione comune per preparare i dati
      const exportData = prepareExportData();
      setCsvData(exportData);
    } catch (error) {
      console.error("Errore nell'esportazione CSV:", error);
    } finally {
      setCsvLoading(false);
    }
  };

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: "dark",
        },
      }),
    []
  );

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Sno.",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => row.index + 1,
      },
      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        enableColumnFilter: true,
      },
      {
        accessorKey: "email",
        header: "Email",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.email ? row.original.email : "-";
        },
      },
      {
        accessorKey: "mobile_no",
        header: "Number",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.email 
            ? row.original.country_code + " " + row.original.mobile_no 
            : "-";
        },
      },
      {
        accessorKey: "item_name",
        header: "Item",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.item_name ? row.original.item_name : "-";
        },
      },
      {
        accessorKey: "category_name",
        header: "Category",
        enableSorting: false,
        enableColumnFilter: true,
        Cell: ({ row }) => {
          return row.original.category_name ? row.original.category_name : "-";
        },
      },
      {
        accessorKey: "request_date",
        header: "Request Date",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return moment(row.original.request_date).format("YYYY-MM-DD");
        },
      },
      {
        accessorKey: "action",
        header: "Action",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <button
              type="button"
              className="btn btn-sm btn-default"
              id="editbtnid"
              onClick={() => deletealertrequest(row.original.id)}
            >
              Delete
            </button>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowSelection: false,
    enableRowNumbers: true,
    initialState: { showColumnFilters: true },
    manualFiltering: false,
    manualPagination: false,
    manualSorting: false,
    enableGlobalFilter: false,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    mrtTheme: (theme) => ({
      baseBackgroundColor: "#1d2330",
      oddRowBackgroundColor: "#1d2330",
      headerBackgroundColor: "#131720",
      draggingBorderColor: "#d1d3e0",
    }),
    muiTableHeadProps: {
      sx: {
        border: "0.1px solid #ffffff1f",
        backgroundColor: "#141820",
      },
    },
    muiTableHeadCellProps: {
      sx: {
        backgroundColor: "#141820",
        textAlign: "center",
        fontSize: "14px",
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid #ffffff1f",
        fontSize: "14px",
        color: "#a2a4a9",
      },
    },
    muiTablePaperProps: {
      elevation: 0,
    },
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      sorting,
    },
  });

  const deletealertrequest = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to Delete this Alert Request!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Deleted it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let res = await deleteAlertRequestAction({ id: id });
        if (res.success) {
          getAlert_Request();
          Swal.fire("Deleted!", res.msg, "success");
        } else {
          Swal.fire("Failed!", res.msg, "error");
        }
      }
    });
  };

  // Calcola il numero totale di record filtrati
  const filteredData = getFilteredData();
  const filteredCount = filteredData.length;

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={tableTheme}>
          <div className="wrapper">
            <Header />
            <Sidebar />
            <div className="content-wrapper">
              <div className="container-full">
                <section className="content">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <div className="box">
                        <div className="box-header with-border">
                          <h4 className="box-title">Alert Request List</h4>
                          <div className="box-controls pull-right">
                            {csvLoading ? (
                              <div className="text-center d-inline-block mr-2">
                                <div className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
                              </div>
                            ) : (
                              <CSVLink 
                                data={prepareExportData()} 
                                filename={`alert-requests-${new Date().toISOString().slice(0, 10)}.csv`} 
                                className="btn btn-sm btn-primary mr-2"
                                separator=";"
                                asyncOnClick={true}
                                onClick={(event, done) => {
                                  setCsvLoading(true);
                                  setTimeout(() => {
                                    setCsvLoading(false);
                                    done();
                                  }, 500);
                                }}
                              >
                                Esporta CSV {columnFilters.length > 0 ? `(${filteredCount} filtrati)` : `(${data.length})`}
                              </CSVLink>
                            )}
                          </div>
                        </div>
                        <div className="box-body">
                          <MaterialReactTable table={table} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <Footer />
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </>
  );
};

export default Alert_Request;
